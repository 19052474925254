export function getTimeAgo(date) {
  const currentDate = new Date();
  const inputDate = new Date(date);
  const timeDifferenceInSeconds = Math.floor((currentDate - inputDate) / 1000);

  const secondsInMinute = 60;
  const secondsInHour = secondsInMinute * 60;
  const secondsInDay = secondsInHour * 24;
  const secondsInMonth = secondsInDay * 30;
  const secondsInYear = secondsInDay * 365;

  if (timeDifferenceInSeconds < secondsInMinute) {
    return `${timeDifferenceInSeconds} second${
      timeDifferenceInSeconds === 1 ? "" : "s"
    } ago`;
  } else if (timeDifferenceInSeconds < secondsInHour) {
    const minutes = Math.floor(timeDifferenceInSeconds / secondsInMinute);
    return `${minutes} minute${minutes === 1 ? "" : "s"} ago`;
  } else if (timeDifferenceInSeconds < secondsInDay) {
    const hours = Math.floor(timeDifferenceInSeconds / secondsInHour);
    return `${hours} hour${hours === 1 ? "" : "s"} ago`;
  } else if (timeDifferenceInSeconds < secondsInMonth) {
    const days = Math.floor(timeDifferenceInSeconds / secondsInDay);
    return `${days} day${days === 1 ? "" : "s"} ago`;
  } else if (timeDifferenceInSeconds < secondsInYear) {
    const months = Math.floor(timeDifferenceInSeconds / secondsInMonth);
    return `${months} month${months === 1 ? "" : "s"} ago`;
  } else {
    const years = Math.floor(timeDifferenceInSeconds / secondsInYear);
    return `${years} year${years === 1 ? "" : "s"} ago`;
  }
}
