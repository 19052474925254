import React, { useState } from "react";
import ShareModal from "./ShareModal";

const ShareWidget = ({ className, shareUrl }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <div className={className}>
      <span
        className="icons-hover"
        data-bs-toggle="tooltip"
        data-bs-placement="bottom"
        title=""
        data-bs-original-title="Share"
        onClick={(event) => {
          event.preventDefault();
          setModalOpen(true);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={18}
          height={18}
          fill="currentColor"
          className="bi bi-share share-icon-white cr"
          viewBox="0 0 16 16"
        >
          <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z" />
        </svg>
      </span>

      <ShareModal
        isOpen={isModalOpen}
        onClose={(event) => {
          event.preventDefault();
          setModalOpen(false);
        }}
        shareUrl={shareUrl}
      />
    </div>
  );
};

export default ShareWidget;
