// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heroCustomImgOnDesktop {
  height: 225px;
  width: 300px;
}
.heroCustomImgOnMobile {
  height: 225px;
  width: 100%;
}

/* .hero-custom-img{
    height: 225px;
    width: 300px;
} */

/* Media query for mobile devices */
/* @media (max-width: 430px) {
  .heroCustomImgOnMobile {
    width: 405px;
  }
} */
/* @media (max-width: 414px) {
  .heroCustomImgOnMobile {
    width: 390px;
  }
}
@media (max-width: 391px) {
  .heroCustomImgOnMobile {
    width: 357px;
  }
}
@media (max-width: 375px) {
  .heroCustomImgOnMobile {
    width: 350px;
  }
} */
`, "",{"version":3,"sources":["webpack://./src/Component/Cards/hero.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;AACd;AACA;EACE,aAAa;EACb,WAAW;AACb;;AAEA;;;GAGG;;AAEH,mCAAmC;AACnC;;;;GAIG;AACH;;;;;;;;;;;;;;GAcG","sourcesContent":[".heroCustomImgOnDesktop {\n  height: 225px;\n  width: 300px;\n}\n.heroCustomImgOnMobile {\n  height: 225px;\n  width: 100%;\n}\n\n/* .hero-custom-img{\n    height: 225px;\n    width: 300px;\n} */\n\n/* Media query for mobile devices */\n/* @media (max-width: 430px) {\n  .heroCustomImgOnMobile {\n    width: 405px;\n  }\n} */\n/* @media (max-width: 414px) {\n  .heroCustomImgOnMobile {\n    width: 390px;\n  }\n}\n@media (max-width: 391px) {\n  .heroCustomImgOnMobile {\n    width: 357px;\n  }\n}\n@media (max-width: 375px) {\n  .heroCustomImgOnMobile {\n    width: 350px;\n  }\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
