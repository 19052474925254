const initialState = {
  vehicles: [],
  page: 1,
  filters: {},
  scrollPosition: 0,
};

export const browseVehiclesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_VEHICLES":
      return { ...state, vehicles: action.payload };

    case "SET_PAGE":
      return { ...state, page: action.payload };

    case "SET_FILTERS":
      return { ...state, filters: action.payload };

    case "SET_SCROLL_POSITION":
      return { ...state, scrollPosition: action.payload };

    default:
      return state;
  }
};
