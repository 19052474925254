const CanadaList = [
  { name: "select state" },
  {
    name: "Alberta",
    shortCode: "AB",
  },
  {
    name: "British Columbia",
    shortCode: "BC",
  },
  {
    name: "Manitoba",
    shortCode: "MB",
  },
  {
    name: "New Brunswick",
    shortCode: "NB",
  },
  {
    name: "Newfoundland and Labrador",
    shortCode: "NL",
  },
  {
    name: "Northwest Territories",
    shortCode: "NT",
  },
  {
    name: "Nova Scotia",
    shortCode: "NS",
  },
  {
    name: "Nunavut",
    shortCode: "NU",
  },
  {
    name: "Ontario",
    shortCode: "ON",
  },
  {
    name: "Prince Edward Island",
    shortCode: "PE",
  },
  {
    name: "Quebec",
    shortCode: "QC",
  },
  {
    name: "Saskatchewan",
    shortCode: "SK",
  },
  {
    name: "Yukon",
    shortCode: "YT",
  },
];
export default CanadaList;
