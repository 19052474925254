import React, { Component } from "react";
import PageLayout from "../../Layout/PageLayout";
import { NavLink } from "react-router-dom";
import CountryList from "../../Data/CountryList";
import BillingCard from "../../Component/Cards/BillingCard";
export class Billing extends Component {
  render() {
    return (
      <>
        <PageLayout
          title="Billing"
          url="https://opposingcylinders.com/"
          description="Find your next collector car or list your vehicle for sale on opposingcylinders.com today!"
          image="https://opposingcylinders.com/static/media/hero-img.0b755dc30efdb71d6784.jpg"
        >
          <>
            {/* content */}
            <div className="sec-pad pt-0 pt-lg-4">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-8 mb-4 mb-lg-0">
                    <div className="billing-box">
                      <div className="h3-title bold">Billing details</div>
                      <form className="mt-4 ">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="mb-1">
                                First Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control fc"
                                placeholder=""
                                name=""
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="mb-1">
                                Last Name <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control fc"
                                placeholder=""
                                name=""
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="mb-1">
                                Company Name (Optional)
                              </label>
                              <input
                                type="text"
                                className="form-control fc"
                                placeholder=""
                                name=""
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="mb-1">
                                Country / Region{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <select className="form-control fc form-select">
                                {CountryList.map((item, index) => (
                                  <option key={index}>
                                    {item.countryName}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="mb-1">
                                Street Address{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control fc mb-2"
                                placeholder="House number and street name"
                                name=""
                              />
                              <input
                                type="text"
                                className="form-control fc"
                                placeholder="Apartment, suite, unit, etc. (optional)"
                                name=""
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="mb-1">
                                State <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control fc"
                                placeholder=""
                                name=""
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="mb-1">
                                Postcode <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control fc"
                                placeholder=""
                                name=""
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="mb-1">
                                Phone <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control fc"
                                placeholder=""
                                name=""
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="mb-1">
                                Email Address{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control fc"
                                placeholder=""
                                name=""
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <label className="cust-check">
                              Create an account?
                              <input type="checkbox" />
                              <span className="checkmark" />
                            </label>
                          </div>
                          <div className="col-12 mt-4">
                            <div className="h3-title bold">
                              Additional information
                            </div>
                            <div className="form-group">
                              <label className="mb-1">
                                Order notes (optional)
                              </label>
                              <textarea
                                className="form-control fc fc-radius-30"
                                rows={5}
                                placeholder="Notes about your order, e.g. special notes for delivery."
                                name=""
                                defaultValue={""}
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <BillingCard />
                  </div>
                </div>
              </div>
            </div>
          </>
        </PageLayout>
      </>
    );
  }
}

export default Billing;
