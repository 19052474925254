import React from "react";
import { useAuth } from "../../authentication";
import { httpClient } from "../../util/Api";
import { message } from "antd";

const LikeWidget = ({ isLiked, vehicleId, className, numberOfLikes }) => {
  const { authUser, getUserLikes, likes } = useAuth();

  const handleLike = (event) => {
    event.preventDefault();
    if (authUser?._id) {
      httpClient.patch(`likes/${vehicleId}`).then(() => {
        getUserLikes();
      });
    } else message.info("Login to like");
  };
  return (
    <div className={`me-2 d-inline-flex align-items-center ${className}`}>
      {/* {likes?.some((el) => el._id === vehicleId)
        ? likes?.find((el) => el._id === vehicleId)?.likes
        : numberOfLikes}{" "}
      &nbsp; */}
      <span
        className="icons-hover"
        data-bs-toggle="tooltip"
        data-bs-placement="bottom"
        title=""
        data-bs-original-title="Like"
      >
        {likes?.some((el) => el._id === vehicleId) ? (
          <svg
            onClick={handleLike}
            width={18}
            height={18}
            fill="#FFD700"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M2 10h4v12H2zM22 9h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L15 2 8.41 8.59C8.15 8.85 8 9.22 8 9.59V20c0 .82.5 1.53 1.22 1.85L11 22h8c.83 0 1.54-.5 1.85-1.22l3-7c.09-.23.15-.47.15-.73v-1C24 10.34 23.66 10 23.24 10H22z" />
          </svg>
        ) : (
          <svg
            onClick={handleLike}
            width={18}
            height={18}
            fill="#D8EFD3"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M2 10h4v12H2zM22 9h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L15 2 8.41 8.59C8.15 8.85 8 9.22 8 9.59V20c0 .82.5 1.53 1.22 1.85L11 22h8c.83 0 1.54-.5 1.85-1.22l3-7c.09-.23.15-.47.15-.73v-1C24 10.34 23.66 10 23.24 10H22z" />
          </svg>
        )}
      </span>
    </div>
  );
};

export default LikeWidget;
