import React from 'react';
import {
    PaymentForm,
    PayloadInput,
    CardNumber,
    Expiry,
    CardCode
} from 'payload-react';
 
export function Payment() {
    return <PaymentForm
      clientToken='test_client_key_3e1Lul7CpIrNxBWoeNkHt'
      className="container"
      styles={{invalid: 'is-invalid'}}
      onProcessed={(evt)=>{
        alert(`Processed: ${evt.transaction_id}`)
      }}
      onError={(evt)=>{
        alert(evt.message)
      }}
    >
        <PayloadInput
         attr="amount" type="hidden" value="10.00"
        />
        <PayloadInput
         attr="billing_address.postal_code" type="hidden" value="11231"
        />
        <div className="row pt-2">
            <div className="form-group col-7 px-1">
                <label>Card</label>
                <CardNumber className="form-control"
                 onInvalid={(evt)=>{
                    alert(evt.message)
                 }}
                />
            </div>
            <div className="form-group col-3 px-1">
                <label>Expiration</label>
                <Expiry className="form-control"
                 onInvalid={(evt)=>{
                    alert(evt.message)
                 }}
                />
            </div>
            <div className="form-group col-2 px-1">
                <label>CVC</label>
                <CardCode className="form-control"/>
            </div>
            
        </div>
        <div className="row pt-2">
            <button className="btn btn-primary" type="submit">
                Pay Now
            </button>
        </div>
    </PaymentForm>
}