import React, { Component } from "react";
import PageLayout from "../../Layout/PageLayout";
export class ListVehicle extends Component {
  render() {
    return (
      <>
        <PageLayout
          type="website"
          imageWidth="1920"
          title="Terms"
          url="https://opposingcylinders.com/"
          description="Find your next collector car or list your vehicle for sale on opposingcylinders.com today!"
          image="https://opposingcylinders.com/static/media/hero-img.0b755dc30efdb71d6784.jpg"
        >
          <header>
            <h1>Payment Terms and Conditions</h1>
          </header>

          <section>
            <h2>1. Introduction</h2>
            <p>
              These Payment Terms and Conditions ("Payment Terms") govern the
              use of online and financial transactions through
              opposingcylinders.com. By listing a vehicle, accessing, or using
              the services provided you agree to be bound by these Payment
              Terms, our Terms of Service, and our Privacy Policy. Please read
              these Payment Terms carefully before engaging in any transactions.
            </p>
          </section>

          <section>
            <h2>2. Listing Fees</h2>
            <p>
              - opposingcylinders.com charges a listing fee for each vehicle
              posted on the platform. The specific fee structure is subject to
              change and will be updated on our website. <br />- All fees are
              payable upon submission of the listing and are non-refundable
              except as expressly provided in these Payment Terms.
            </p>
          </section>

          <section>
            <h2>3. Commission on Sales</h2>
            <p>
              - opposingcylinders.com charges a commission on certain vehicle
              sales made through the platform. It will be explicitly stated to
              you, the user, when selecting a package whether or not your
              vehicle listing is liable to additional commission fees. <br />
              - Commissions are only charged on successful transactions where
              the sale is completed and confirmed by both the buyer and seller.{" "}
              <br />- opposingcylinders.com reserves the right to adjust its
              commission rates.
            </p>
          </section>

          <section>
            <h2>4. Payment Processing</h2>
            <p>
              - All financial transactions facilitated by opposingcylinders.com
              are processed using a secure third-party payment processor. <br />
              - Users are required to provide accurate and up-to-date payment
              information. opposingcylinders.com is not responsible for any
              losses or fees resulting from incorrect payment information
              provided by users. <br />- Payment processing fees may apply to
              certain transactions. These fees will be disclosed at the time of
              the transaction.
            </p>
          </section>

          <section>
            <h2>5. Refunds and Cancellations</h2>
            <p>
              - Listing fees are non-refundable except in cases where
              opposingcylinders.com fails to publish the listing due to
              technical issues or breaches its obligations under the Terms of
              Service. <br />
              - In the event of a sale cancellation, the commission fee may be
              refundable at the discretion of opposingcylinders.com depending on
              the circumstances leading to the cancellation. <br />- Buyers and
              sellers are encouraged to resolve disputes amicably.
              opposingcylinders.com reserves the right to intervene in disputes
              at its discretion but is not obligated to issue refunds or
              facilitate cancellations.
            </p>
          </section>

          <section>
            <h2>6. Dispute Resolution</h2>
            <p>
              - opposingcylinders.com will investigate all reported disputes and
              take appropriate action in accordance with these Payment Terms and
              the Terms of Service.
            </p>
          </section>

          <section>
            <h2>7. Changes to Payment Terms</h2>
            <p>
              - opposingcylinders.com reserves the right to modify these Payment
              Terms at any time. Any changes will be effective immediately upon
              posting on our website. <br />- Continued use of Opposing
              Cylinder's services after such changes will constitute acceptance
              of the new Payment Terms.
            </p>
          </section>
        </PageLayout>
      </>
    );
  }
}

export default ListVehicle;
