import React, { Component } from "react";
import { NavLink } from "react-router-dom";

export class ListVehiclesCard extends Component {
  render() {
    const { src, srcHover, title, price, to } = this.props;
    return (
      <NavLink to={to}>
        <div className="col">
          <div className="l-box">
            <div className="mb-4">
              <img src={src} className="img-fluid l-box-img" alt="icon" />
              <img
                src={srcHover}
                className="img-fluid l-box-img-hover"
                alt="icon"
              />
            </div>
            <p className="mb-2">{title}</p>
            <h3 className="bold h3-title mb-0">${price}</h3>
          </div>
        </div>
      </NavLink>
    );
  }
}

export default ListVehiclesCard;
