// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-container {
    display: flex;
    justify-content: center; /* Center the buttons horizontally */
    gap: 10px; /* Add space between the buttons */
    margin-top: 50px; /* Optional: add some margin on top */
  }
  
  .small-button {
    padding: 5px 10px; /* Adjust padding for smaller buttons */
    font-size: 14px; /* Adjust font size */
    border-radius: 10px; /* Optional: make buttons slightly rounded */
  }
  `, "",{"version":3,"sources":["webpack://./src/Pages/Thanksforaddingparts/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB,EAAE,oCAAoC;IAC7D,SAAS,EAAE,kCAAkC;IAC7C,gBAAgB,EAAE,qCAAqC;EACzD;;EAEA;IACE,iBAAiB,EAAE,uCAAuC;IAC1D,eAAe,EAAE,qBAAqB;IACtC,mBAAmB,EAAE,4CAA4C;EACnE","sourcesContent":[".button-container {\n    display: flex;\n    justify-content: center; /* Center the buttons horizontally */\n    gap: 10px; /* Add space between the buttons */\n    margin-top: 50px; /* Optional: add some margin on top */\n  }\n  \n  .small-button {\n    padding: 5px 10px; /* Adjust padding for smaller buttons */\n    font-size: 14px; /* Adjust font size */\n    border-radius: 10px; /* Optional: make buttons slightly rounded */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
