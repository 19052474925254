const VideoList = [
  {
    id: 0,
    title:
      "0 Travel from Bar Harbor, Maine and Acadia National Park down to Boston, Massachusetts!",
    videoLink:
      "https://www.youtube.com/embed/Wi3cYIzEdt0?si=XcPsS77JElL5qMv?rel=0",
    noOFLike: "2222",
    name: "Kelvin",
    date: "12 Jan 2023",
    detail:
      "0In this episode we go east to Bar Harbor, Maine and hike in Acadia National Park. Our journey takes us on several hikes before we make the drive south to Boston, Massachusetts. In Boston we visit several historical sites and the freedom trail.",
  },
  {
    id: 1,
    title:
      "1Travel from Bar Harbor, Maine and Acadia National Park down to Boston, Massachusetts!",
    videoLink:
      "https://www.youtube.com/embed/Wi3cYIzEdt0?si=XcPsS77JElL5qMv?rel=0",
    noOFLike: "2222",
    name: "Kelvin",
    date: "12 Jan 2023",
    detail:
      "1In this episode we go east to Bar Harbor, Maine and hike in Acadia National Park. Our journey takes us on several hikes before we make the drive south to Boston, Massachusetts. In Boston we visit several historical sites and the freedom trail.",
  },
  {
    id: 2,
    title:
      "2 Travel from Bar Harbor, Maine and Acadia National Park down to Boston, Massachusetts!",
    videoLink:
      "https://www.youtube.com/embed/Wi3cYIzEdt0?si=XcPsS77JElL5qMv?rel=0",
    noOFLike: "2222",
    name: "Kelvin",
    date: "12 Jan 2023",
    detail:
      "2 In this episode we go east to Bar Harbor, Maine and hike in Acadia National Park. Our journey takes us on several hikes before we make the drive south to Boston, Massachusetts. In Boston we visit several historical sites and the freedom trail.",
  },
  {
    id: 3,
    title:
      "3 Travel from Bar Harbor, Maine and Acadia National Park down to Boston, Massachusetts!",
    videoLink:
      "https://www.youtube.com/embed/Wi3cYIzEdt0?si=XcPsS77JElL5qMv?rel=0",
    noOFLike: "2222",
    name: "Kelvin",
    date: "12 Jan 2023",
    detail:
      "3 In this episode we go east to Bar Harbor, Maine and hike in Acadia National Park. Our journey takes us on several hikes before we make the drive south to Boston, Massachusetts. In Boston we visit several historical sites and the freedom trail.",
  },
  {
    id: 4,
    title:
      "4 Travel from Bar Harbor, Maine and Acadia National Park down to Boston, Massachusetts!",
    videoLink:
      "https://www.youtube.com/embed/Wi3cYIzEdt0?si=XcPsS77JElL5qMv?rel=0",
    noOFLike: "2222",
    name: "Kelvin",
    date: "12 Jan 2023",
    detail:
      "4 In this episode we go east to Bar Harbor, Maine and hike in Acadia National Park. Our journey takes us on several hikes before we make the drive south to Boston, Massachusetts. In Boston we visit several historical sites and the freedom trail.",
  },
  {
    id: 5,
    title:
      "5 Travel from Bar Harbor, Maine and Acadia National Park down to Boston, Massachusetts!",
    videoLink:
      "https://www.youtube.com/embed/Wi3cYIzEdt0?si=XcPsS77JElL5qMv?rel=0",
    noOFLike: "2222",
    name: "Kelvin",
    date: "12 Jan 2023",
    detail:
      "5 In this episode we go east to Bar Harbor, Maine and hike in Acadia National Park. Our journey takes us on several hikes before we make the drive south to Boston, Massachusetts. In Boston we visit several historical sites and the freedom trail.",
  },
  {
    id: 6,
    title:
      "6 Travel from Bar Harbor, Maine and Acadia National Park down to Boston, Massachusetts!",
    videoLink:
      "https://www.youtube.com/embed/Wi3cYIzEdt0?si=XcPsS77JElL5qMv?rel=0",
    noOFLike: "2222",
    name: "Kelvin",
    date: "12 Jan 2023",
    detail:
      "6 In this episode we go east to Bar Harbor, Maine and hike in Acadia National Park. Our journey takes us on several hikes before we make the drive south to Boston, Massachusetts. In Boston we visit several historical sites and the freedom trail.",
  },
  {
    id: 7,
    title:
      "7 Travel from Bar Harbor, Maine and Acadia National Park down to Boston, Massachusetts!",
    videoLink:
      "https://www.youtube.com/embed/Wi3cYIzEdt0?si=XcPsS77JElL5qMv?rel=0",
    noOFLike: "2222",
    name: "Kelvin",
    date: "12 Jan 2023",
    detail:
      "7 In this episode we go east to Bar Harbor, Maine and hike in Acadia National Park. Our journey takes us on several hikes before we make the drive south to Boston, Massachusetts. In Boston we visit several historical sites and the freedom trail.",
  },
  {
    id: 8,
    title:
      "9 Travel from Bar Harbor, Maine and Acadia National Park down to Boston, Massachusetts!",
    videoLink:
      "https://www.youtube.com/embed/Wi3cYIzEdt0?si=XcPsS77JElL5qMv?rel=0",
    noOFLike: "2222",
    name: "Kelvin",
    date: "12 Jan 2023",
    detail:
      "8 In this episode we go east to Bar Harbor, Maine and hike in Acadia National Park. Our journey takes us on several hikes before we make the drive south to Boston, Massachusetts. In Boston we visit several historical sites and the freedom trail.",
  },
  {
    id: 9,
    title:
      "9 Travel from Bar Harbor, Maine and Acadia National Park down to Boston, Massachusetts!",
    videoLink:
      "https://www.youtube.com/embed/Wi3cYIzEdt0?si=XcPsS77JElL5qMv?rel=0",
    noOFLike: "2222",
    name: "Kelvin",
    date: "12 Jan 2023",
    detail:
      "9 In this episode we go east to Bar Harbor, Maine and hike in Acadia National Park. Our journey takes us on several hikes before we make the drive south to Boston, Massachusetts. In Boston we visit several historical sites and the freedom trail.",
  },
];
export default VideoList;
