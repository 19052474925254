// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/**
  Component
**/
.card-input{
border: 1px solid #ccc;
border-radius: 10px;

}
label {
    width: 100%;
}

.card-input-element {
    display: none;
}

.card-input {
    margin: 10px;
    padding: 1rem;
}

.card-input:hover {
    cursor: pointer;
}

.card-input-element:checked + .card-input {
     box-shadow: 0 0 1px 1px #183869;
 }

.payment-selection-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  
}`, "",{"version":3,"sources":["webpack://./src/Component/Modal/card.css"],"names":[],"mappings":";AACA;;EAEE;AACF;AACA,sBAAsB;AACtB,mBAAmB;;AAEnB;AACA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,eAAe;AACnB;;AAEA;KACK,+BAA+B;CACnC;;AAED;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,8BAA8B;IAC9B,WAAW;;AAEf","sourcesContent":["\n/**\n  Component\n**/\n.card-input{\nborder: 1px solid #ccc;\nborder-radius: 10px;\n\n}\nlabel {\n    width: 100%;\n}\n\n.card-input-element {\n    display: none;\n}\n\n.card-input {\n    margin: 10px;\n    padding: 1rem;\n}\n\n.card-input:hover {\n    cursor: pointer;\n}\n\n.card-input-element:checked + .card-input {\n     box-shadow: 0 0 1px 1px #183869;\n }\n\n.payment-selection-container{\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n    width: 100%;\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
