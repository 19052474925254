import React, { Component } from "react";
import PageLayout from "../../Layout/PageLayout";
import CartCard from "../../Component/Cards/CartCard";
export class Cart extends Component {
  render() {
    return (
      <>
        <PageLayout
          type="website"
          imageWidth="1920"
          title="Cart"
          url="https://opposingcylinders.com/"
          description="Find your next collector car or list your vehicle for sale on opposingcylinders.com today!"
          image="https://opposingcylinders.com/static/media/hero-img.0b755dc30efdb71d6784.jpg"
        >
          <>
            {/* content */}
            <div className="sec-pad pt-0 pt-lg-4">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-7 col-xl-8 mb-4 mb-lg-0">
                    <div className="billing-box">
                      <div className="table2">
                        <div className="table2_header">
                          <div>
                            <div>Action</div>
                            <div>Image</div>
                            <div>Product</div>
                            <div>Price</div>
                            <div>Quantity</div>
                            <div>Subtotal</div>
                          </div>
                        </div>
                        <div className="table2_body">
                          <CartCard />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-8 col-lg-5 col-xl-4">
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control br-5"
                        placeholder="Coupon Code"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                      />
                      <span
                        className="input-group-text btn btn-theme-fill"
                        id="basic-addon2"
                      >
                        Apply Coupon
                      </span>
                    </div>
                    <div className="billing-box">
                      <div className="d-flex justify-content-between mb-2">
                        <div className="text-theme">Subtotal</div>
                        <div className="text-theme">$15.00</div>
                      </div>
                      <div className="d-flex justify-content-between mb-2">
                        <div className="text-theme">Coupon Code</div>
                        <div className="text-theme">- $5.00</div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="text-theme">Tax</div>
                        <div className="text-theme">$0.00</div>
                      </div>
                      <p>(estimated for Australia)</p>
                      <hr />
                      <div className="d-flex justify-content-between mb-2">
                        <div className="text-theme">Total</div>
                        <div className="text-theme">$10.00</div>
                      </div>
                      <div className="mt-4 mt-md-5">
                        <div className="mt-4 mb-3 text-center">
                          <button className="btn btn-theme-fill">
                            Checkout
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
          <>
            {/* Delete Modal */}
            <div
              className="modal fade"
              id="deleteModal"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body text-center">
                    <button
                      type="button"
                      className="btn-close modal-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                    <div className="mb-3 mt-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={56}
                        height={56}
                        fill="currentColor"
                        className="bi bi-x-circle text-danger"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                      </svg>
                    </div>
                    <h2 className="text-theme bold h2-title mb-3">
                      Are you sure?
                    </h2>
                    <p>
                      Do you really want to delete these records? This process
                      cannot be undone.
                    </p>
                    <div className=" mt-4 mt-md-4 mb-4">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>{" "}
                      &nbsp;&nbsp;
                      <button type="button" className="btn btn-danger">
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </PageLayout>
      </>
    );
  }
}

export default Cart;
