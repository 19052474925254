export const setVehicles = (vehicles) => ({
  type: "SET_VEHICLES",
  payload: vehicles,
});

export const setPage = (page) => ({
  type: "SET_PAGE",
  payload: page,
});

export const setScrollPosition = (position) => ({
  type: "SET_SCROLL_POSITION",
  payload: position,
});
