import Img1 from "../Assets/Images/car/IMG_0594-1.jpg";
import Img2 from "../Assets/Images/car/IMG_0594-2.jpg";
import Img3 from "../Assets/Images/car/IMG_0594-3.jpg";
import Img4 from "../Assets/Images/car/IMG_0594-4.jpg";
import Img5 from "../Assets/Images/car/IMG_0594-5.jpg";
import Img6 from "../Assets/Images/car/IMG_0594-6.jpg";
import Img7 from "../Assets/Images/car/IMG_0594-7.jpg";
import Img8 from "../Assets/Images/car/IMG_0594-8.jpg";
import Img9 from "../Assets/Images/car/IMG_0594-9.jpg";
import Img10 from "../Assets/Images/car/IMG_0594-10.jpg";
import Img11 from "../Assets/Images/car/IMG_0594-11.jpg";
import Img12 from "../Assets/Images/car/IMG_0594-11.jpg";
const VehicleList = [
  {
    id: 0,
    noOfLike: "222",
    title: "1950 Ford Open to Offers/Motivated Seller",
    price: "$788",
    year: "1990",
    noOfImage: "8",
    location: "Wyoming, MN, USA",
    availbility: "Confirm Availability",
    registered: "Online Paper Work",
    number: "(123) 456-7890",
    certification: "BMW Certified",
    testing: "Video Walkaround | Test Drive | Delivery",
    drivenMile: "41,772 ",
    detail:
      "Body Mods: Flat hood no bead or emblems, One off custom under hood bracing, smooth firewall, smooth windshield wiper cowl panel, one piece fenders, drip rails shaved,front doors have been radiused in the front to mimic 55 truck doors, shaved handles on doors and hatch, gas lid door shaved.All seams inside doors and jambs have been welded and smoothed.All body seams have been welded and smoothed.Bumpers have been tucked and pulled into body.All chrome has been done by Advanced Plating.",
    interior:
      "Custom 1955 Truck dash with custom garnish moldings to match dash. custom blue interior by Sewn Tight.",
    moreInfo: [
      { subInfo: "Motor LS3" },
      { subInfo: "Billet Specialties front runner" },
      { subInfo: "PRC polished radiator and condenser" },
      { subInfo: "Speartech ECM" },
      { subInfo: "4L70 Transmission" },
      { subInfo: "Fat Man Fabrications Frame powder coated charcoal" },
      {
        subInfo:
          "Tubular Control Arms powder coated charcoal 9 inch rear end with strange internals",
      },
    ],
    imgSrc: [
      { subImage: Img1 },
      { subImage: Img2 },
      { subImage: Img3 },
      { subImage: Img4 },
      { subImage: Img5 },
      { subImage: Img6 },
      { subImage: Img7 },
      { subImage: Img8 },
      { subImage: Img9 },
      { subImage: Img10 },
      { subImage: Img11 },
      { subImage: Img12 },
    ],
    comments: [
      {
        name: "John Deo1",
        time: "3 days ago",
        descrition:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation",
      },
    ],
  },
  {
    id: 1,
    noOfLike: "222",
    title: " 1 1950 Ford Open to Offers/Motivated Seller",
    price: "$788",
    year: "1990",
    noOfImage: "8",
    location: "Wyoming, MN, USA",
    availbility: "Confirm Availability",
    registered: "Online Paper Work",
    number: "(123) 456-7890",
    certification: "BMW Certified",
    testing: "Video Walkaround | Test Drive | Delivery",
    drivenMile: "41,772 ",
    detail:
      "1 Body Mods: Flat hood no bead or emblems, One off custom under hood bracing, smooth firewall, smooth windshield wiper cowl panel, one piece fenders, drip rails shaved,front doors have been radiused in the front to mimic 55 truck doors, shaved handles on doors and hatch, gas lid door shaved.All seams inside doors and jambs have been welded and smoothed.All body seams have been welded and smoothed.Bumpers have been tucked and pulled into body.All chrome has been done by Advanced Plating.",
    interior:
      "Custom 1955 Truck dash with custom garnish moldings to match dash. custom blue interior by Sewn Tight.",
    moreInfo: [
      { subInfo: "Motor LS3" },
      { subInfo: "Billet Specialties front runner" },
      { subInfo: "PRC polished radiator and condenser" },
      { subInfo: "Speartech ECM" },
      { subInfo: "4L70 Transmission" },
      { subInfo: "Fat Man Fabrications Frame powder coated charcoal" },
      {
        subInfo:
          "Tubular Control Arms powder coated charcoal 9 inch rear end with strange internals",
      },
    ],
    imgSrc: [
      { subImage: Img1 },
      { subImage: Img2 },
      { subImage: Img3 },
      { subImage: Img4 },
      { subImage: Img5 },
      { subImage: Img6 },
      { subImage: Img7 },
      { subImage: Img8 },
      { subImage: Img9 },
      { subImage: Img10 },
      { subImage: Img11 },
      { subImage: Img12 },
    ],
    comments: [
      {
        name: "John Deo1",
        time: "3 days ago",
        descrition:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation",
      },
    ],
  },
  {
    id: 2,
    noOfLike: "222",
    title: "2 1950 Ford Open to Offers/Motivated Seller",
    price: "$788",
    year: "1990",
    noOfImage: "8",
    location: "Wyoming, MN, USA",
    availbility: "Confirm Availability",
    registered: "Online Paper Work",
    number: "(123) 456-7890",
    certification: "BMW Certified",
    testing: "Video Walkaround | Test Drive | Delivery",
    drivenMile: "41,772 ",
    detail:
      "2 Body Mods: Flat hood no bead or emblems, One off custom under hood bracing, smooth firewall, smooth windshield wiper cowl panel, one piece fenders, drip rails shaved,front doors have been radiused in the front to mimic 55 truck doors, shaved handles on doors and hatch, gas lid door shaved.All seams inside doors and jambs have been welded and smoothed.All body seams have been welded and smoothed.Bumpers have been tucked and pulled into body.All chrome has been done by Advanced Plating.",
    interior:
      "Custom 1955 Truck dash with custom garnish moldings to match dash. custom blue interior by Sewn Tight.",
    moreInfo: [
      { subInfo: "Motor LS3" },
      { subInfo: "Billet Specialties front runner" },
      { subInfo: "PRC polished radiator and condenser" },
      { subInfo: "Speartech ECM" },
      { subInfo: "4L70 Transmission" },
      { subInfo: "Fat Man Fabrications Frame powder coated charcoal" },
      {
        subInfo:
          "Tubular Control Arms powder coated charcoal 9 inch rear end with strange internals",
      },
    ],
    imgSrc: [
      { subImage: Img1 },
      { subImage: Img2 },
      { subImage: Img3 },
      { subImage: Img4 },
      { subImage: Img5 },
      { subImage: Img6 },
      { subImage: Img7 },
      { subImage: Img8 },
      { subImage: Img9 },
      { subImage: Img10 },
      { subImage: Img11 },
      { subImage: Img12 },
    ],
    comments: [
      {
        name: "John Deo1",
        time: "3 days ago",
        descrition:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation",
      },
    ],
  },
  {
    id: 3,
    noOfLike: "222",
    title: "3 1950 Ford Open to Offers/Motivated Seller",
    price: "$788",
    year: "1990",
    noOfImage: "8",
    location: "Wyoming, MN, USA",
    availbility: "Confirm Availability",
    registered: "Online Paper Work",
    number: "(123) 456-7890",
    certification: "BMW Certified",
    testing: "Video Walkaround | Test Drive | Delivery",
    drivenMile: "41,772 ",
    detail:
      "3 Body Mods: Flat hood no bead or emblems, One off custom under hood bracing, smooth firewall, smooth windshield wiper cowl panel, one piece fenders, drip rails shaved,front doors have been radiused in the front to mimic 55 truck doors, shaved handles on doors and hatch, gas lid door shaved.All seams inside doors and jambs have been welded and smoothed.All body seams have been welded and smoothed.Bumpers have been tucked and pulled into body.All chrome has been done by Advanced Plating.",
    interior:
      "Custom 1955 Truck dash with custom garnish moldings to match dash. custom blue interior by Sewn Tight.",
    moreInfo: [
      { subInfo: "Motor LS3" },
      { subInfo: "Billet Specialties front runner" },
      { subInfo: "PRC polished radiator and condenser" },
      { subInfo: "Speartech ECM" },
      { subInfo: "4L70 Transmission" },
      { subInfo: "Fat Man Fabrications Frame powder coated charcoal" },
      {
        subInfo:
          "Tubular Control Arms powder coated charcoal 9 inch rear end with strange internals",
      },
    ],
    imgSrc: [
      { subImage: Img1 },
      { subImage: Img2 },
      { subImage: Img3 },
      { subImage: Img4 },
      { subImage: Img5 },
      { subImage: Img6 },
      { subImage: Img7 },
      { subImage: Img8 },
      { subImage: Img9 },
      { subImage: Img10 },
      { subImage: Img11 },
      { subImage: Img12 },
    ],
    comments: [
      {
        name: "John Deo1",
        time: "3 days ago",
        descrition:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation",
      },
    ],
  },
  {
    id: 4,
    noOfLike: "222",
    title: "4 1950 Ford Open to Offers/Motivated Seller",
    price: "$788",
    year: "1990",
    noOfImage: "8",
    location: "Wyoming, MN, USA",
    availbility: "Confirm Availability",
    registered: "Online Paper Work",
    number: "(123) 456-7890",
    certification: "BMW Certified",
    testing: "Video Walkaround | Test Drive | Delivery",
    drivenMile: "41,772 ",
    detail:
      "4 Body Mods: Flat hood no bead or emblems, One off custom under hood bracing, smooth firewall, smooth windshield wiper cowl panel, one piece fenders, drip rails shaved,front doors have been radiused in the front to mimic 55 truck doors, shaved handles on doors and hatch, gas lid door shaved.All seams inside doors and jambs have been welded and smoothed.All body seams have been welded and smoothed.Bumpers have been tucked and pulled into body.All chrome has been done by Advanced Plating.",
    interior:
      "Custom 1955 Truck dash with custom garnish moldings to match dash. custom blue interior by Sewn Tight.",
    moreInfo: [
      { subInfo: "Motor LS3" },
      { subInfo: "Billet Specialties front runner" },
      { subInfo: "PRC polished radiator and condenser" },
      { subInfo: "Speartech ECM" },
      { subInfo: "4L70 Transmission" },
      { subInfo: "Fat Man Fabrications Frame powder coated charcoal" },
      {
        subInfo:
          "Tubular Control Arms powder coated charcoal 9 inch rear end with strange internals",
      },
    ],
    imgSrc: [
      { subImage: Img1 },
      { subImage: Img2 },
      { subImage: Img3 },
      { subImage: Img4 },
      { subImage: Img5 },
      { subImage: Img6 },
      { subImage: Img7 },
      { subImage: Img8 },
      { subImage: Img9 },
      { subImage: Img10 },
      { subImage: Img11 },
      { subImage: Img12 },
    ],
    comments: [
      {
        name: "John Deo1",
        time: "3 days ago",
        descrition:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation",
      },
    ],
  },
  {
    id: 5,
    noOfLike: "222",
    title: "4 1950 Ford Open to Offers/Motivated Seller",
    price: "$788",
    year: "1990",
    noOfImage: "8",
    location: "Wyoming, MN, USA",
    availbility: "Confirm Availability",
    registered: "Online Paper Work",
    number: "(123) 456-7890",
    certification: "BMW Certified",
    testing: "Video Walkaround | Test Drive | Delivery",
    drivenMile: "41,772 ",
    detail:
      "4 Body Mods: Flat hood no bead or emblems, One off custom under hood bracing, smooth firewall, smooth windshield wiper cowl panel, one piece fenders, drip rails shaved,front doors have been radiused in the front to mimic 55 truck doors, shaved handles on doors and hatch, gas lid door shaved.All seams inside doors and jambs have been welded and smoothed.All body seams have been welded and smoothed.Bumpers have been tucked and pulled into body.All chrome has been done by Advanced Plating.",
    interior:
      "Custom 1955 Truck dash with custom garnish moldings to match dash. custom blue interior by Sewn Tight.",
    moreInfo: [
      { subInfo: "Motor LS3" },
      { subInfo: "Billet Specialties front runner" },
      { subInfo: "PRC polished radiator and condenser" },
      { subInfo: "Speartech ECM" },
      { subInfo: "4L70 Transmission" },
      { subInfo: "Fat Man Fabrications Frame powder coated charcoal" },
      {
        subInfo:
          "Tubular Control Arms powder coated charcoal 9 inch rear end with strange internals",
      },
    ],
    imgSrc: [
      { subImage: Img1 },
      { subImage: Img2 },
      { subImage: Img3 },
      { subImage: Img4 },
      { subImage: Img5 },
      { subImage: Img6 },
      { subImage: Img7 },
      { subImage: Img8 },
      { subImage: Img9 },
      { subImage: Img10 },
      { subImage: Img11 },
      { subImage: Img12 },
    ],
    comments: [
      {
        name: "John Deo1",
        time: "3 days ago",
        descrition:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation",
      },
    ],
  },
];
export default VehicleList;
