import React, { useState, useEffect, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import PageLayout from "../../Layout/PageLayout";
import { NavLink, unstable_HistoryRouter, useNavigate } from "react-router-dom";
import Cloud from "../../Assets/Images/upload-cloud-svgrepo-com.svg";
import CountryList from "../../Data/CountryList";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Col, Input, Radio, Select, Space, Spin, Tooltip, message } from "antd";
import * as Yup from "yup";

import { baseURL, httpClient } from "../../util/Api";
import PaymentModal from "../../Component/Modal/PaymentModal";
import { packages } from "../../Data/packages";
import { PatternFormat } from "react-number-format";
import Demo from "./ImagecropperAdd";
import Dragger from "antd/es/upload/Dragger";
import CustomLoader from "../../Component/CustomLoader/CustomLoader";
import { set } from "lodash";
import { useAuth } from "../../authentication";
import Dropzone from "dropzone";
import "dropzone/dist/dropzone.css";

const bodyStyle = [
  "--",
  "Sedan",
  "Coupe",
  "Convertible",
  "Hatchback",
  "Station Wagon",
  "SUV",
  "Minivan",
  "Truck",
];
const transmissionType = ["--", "Automatic", "Manual"];

const ListVehicleStep = () => {
  const { authUser } = useAuth();
  const dropzoneRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [packageType, setPackageType] = useState("");
  const [id, setId] = useState("");
  const [step, setStep] = useState(0);
  const [imageURLs, setImageURLs] = useState([]);
  const [carImages, setCarImages] = useState("");
  const [croppedImage, setCroppedImage] = useState("");
  const [autoThumbnail, setAutoThumbnail] = useState([]);
  const [value, setValue] = useState(1);
  const [isElse, setIsElse] = useState(false);

  const [imageToCrop, setImageToCrop] = useState("");
  const [open, setOpen] = useState(false);
  const [editorHtml, setEditorHtml] = useState("");
  const [detail, setDetail] = useState("");
  const quillRef = useRef(null);
  const [initialLoading, setInitialLoading] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const isSmallScreen = window.innerWidth <= 768;
  const navigate = useNavigate();

  console.log(authUser, "authUser");
  useEffect(() => {
    setInitialLoading(true);
    const dropzone = new Dropzone(dropzoneRef.current, {
      url: `${baseURL}/ping`,
      maxFiles: 1,
      method: "GET",
      acceptedFiles: "image/jpeg, image/png, image/jpg, image/webp",
      dictDefaultMessage: `
        <p className="ant-upload-drag-icon">
          <div className="img-icon-list">
            <span>
              <img src="${Cloud}" className="img-fluid" alt="img" />
            </span>
          </div>
        </p>
        <p className="ant-upload-text">Drop your files here or</p>
        <p className="ant-upload-hint">Browse</p>
      `,
      previewTemplate: document.querySelector("#custom-preview-template")
        .innerHTML,
      addRemoveLinks: false,
      init: function () {
        this.on("addedfile", function (file) {
          if (this.files.length > 1) {
            this.removeFile(this.files[0]);
          }
        });

        this.on("success", function (file) {
          // Handle success, e.g., set uploaded image data here
        });

        this.on("error", function (file, response) {
          if (file.accepted === false) {
            this.removeFile(file); // Remove the file if it's not accepted
            message.error(
              "Invalid file type. Please upload a JPEG, PNG, JPG, or WebP file."
            );
          }
        });
      },
    });

    dropzone.on("addedfile", (file) => {
      setUploadedImage(file);
    });

    // Cleanup function
    return () => {
      dropzone.destroy();
    };
  }, [initialLoading]);

  useEffect(() => {
    // Get the dropzone container element
    const dropzoneContainer = dropzoneRef.current;

    // Handler function for click events
    const handleIconClick = (event) => {
      if (event.target.matches(".fa-image")) {
        handleThumbnailImage();
        // Your logic here
      }
    };

    // Add event listener
    dropzoneContainer.addEventListener("click", handleIconClick);

    // Cleanup the event listener
    return () => {
      dropzoneContainer.removeEventListener("click", handleIconClick);
    };
  }, [initialLoading, uploadedImage]);
  useEffect(() => {
    if (quillRef.current) {
      let quill = quillRef.current.getEditor();
      quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
        let plaintext = node.innerText;
        delta.ops = [{ insert: plaintext }];
        return delta;
      });
    }
  }, []);
  const handleEditorChange = (e, html) => {
    setEditorHtml(e);
    setDetail(e);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const type = queryParams.get("type");
    const packageType =
      packages.find((el) => el.type === type)?.type || "basic";
    setPackageType(packageType);
  }, []);

  const handlePackageChange = (value) => {
    setPackageType(value.value);
  };

  const handleNext = () => {
    if (step === 1 && !uploadedImage) {
      return message.error("Please upload an image");
    }
    setStep((prevStep) => prevStep + 1);
    window.scrollTo(0, 0);
  };

  const handlePrevious = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleThumbnailImage = (image) => {
    try {
      setImageToCrop(uploadedImage.dataURL);
      setOpen(true);
    } catch (error) {
      console.error("Error fetching image", error);
    }
  };

  const handleDeleteImage = () => {
    setCarImages("");
  };
  const handleOk = async (values, clearForm, paymentLoading) => {
    paymentLoading.start();
    const endpoint =
      values.type === "card" && values.user_role
        ? `vehicles/instant-payment/${id}`
        : `vehicles/payment/${id}`;

    try {
      await httpClient.post(endpoint, {
        type: values.type,
        payment: { ...values },
      });
      setPaymentModal(false);
      setLoading(false);
      paymentLoading.success();
      window.location.replace("/listed-vehicle");
      clearForm();
    } catch {
      paymentLoading.error();
      message.error("Payment Failed");
    }
  };

  const onChange = (e) => {
    if (e.target.value === 1) {
      setIsElse(false);
    } else if (e.target.value === 2) {
      setIsElse(true);
    }
    setValue(e.target.value);
  };

  const steps = ["Vehicle Information", "Upload A Photo", "Payment"];
  useEffect(() => {}, [authUser]);
  return (
    <>
      {loading && (
        <CustomLoader text="Please wait while your vehicle is added...." />
      )}

      {/* <Spin tip="Loading..." spinning={loading} delay={200} size="large"> */}
      <PageLayout
        title="Add Vehicle"
        type="website"
        imageWidth="1920"
        url="https://opposingcylinders.com/"
        description="Find your next collector car or list your vehicle for sale on opposingcylinders.com today!"
        image="https://opposingcylinders.com/static/media/hero-img.0b755dc30efdb71d6784.jpg"
      >
        <div
          style={{
            backgroundColor: "#003366",
            color: "white",
            fontFamily: "Arial, sans-serif",
            padding: "10px 20px",
            textAlign: "center",
          }}
        >
          <p>
            If you are experiencing any technical problems with our new website,
            please contact us at (937) 430-0242.
          </p>
          <p>We can help you finalize your orders.</p>
        </div>

        <div className="sec-pad pt-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-12 col-lg-11">
                <div className="text-center mb-4">
                  <h2 className="h2-title mb-2 bold">List Your Vehicle</h2>
                </div>
                <div className="billing-box bb-mobile">
                  <Formik
                    initialValues={{
                      make: "",
                      model: "",
                      year: "",
                      exteriorColor: "",
                      price: "",
                      miles: "",
                      shirtSize: "",
                      address: "",
                      postalCode: "",
                      country: "",
                      city: "",
                      state: "",
                      location: "",
                      locationTwo: "",
                      number: authUser?.phone || "",
                      altNumber: "",
                      bodyStyle: null,
                      transmissionType: null,
                    }}
                    validationSchema={Yup.object({
                      make: Yup.string().required("Vehicle make is required"),
                      year: Yup.number().required("Year is required"),
                      model: Yup.string().required("Vehicle model is required"),
                      exteriorColor: Yup.string().required(
                        "Exterior Color is required"
                      ),
                      price: Yup.string().required("Price is required"),
                      miles: Yup.string().required("Miles is required"),
                      country: Yup.string().required("country is required"),
                      shirtSize: Yup.string(),
                      // address: Yup.string().required("Address is required"),
                      // postalCode: Yup.string().required(
                      //   "Postal code is required"
                      // ),
                      // city: Yup.string().required("city is required"),
                      // state: Yup.string().required("state is required"),

                      bodyStyle: Yup.string().required(
                        "Body Style is required"
                      ),
                      transmissionType: Yup.string().required(
                        "Transmission Type is required"
                      ),
                      altNumber: Yup.string().matches(
                        /^\+1 \([0-9]{3}\) [0-9]{3}-[0-9]{4}$/,
                        "Invalid phone number"
                      ),
                      number: Yup.string().matches(
                        /^\+1 \([0-9]{3}\) [0-9]{3}-[0-9]{4}$/,
                        "Invalid phone number"
                      ),
                    })}
                    onSubmit={async (values, actions) => {
                      setLoading(true);

                      if (detail == "" || detail === "<p><br></p>") {
                        return (
                          message.error("please Add detail"), setLoading(false)
                        );
                      }
                      values.address = values.address
                        ? values.address
                        : !isElse
                        ? authUser.location
                        : "";
                      values.postalCode = values.postalCode
                        ? values.postalCode
                        : !isElse
                        ? authUser.postCode
                        : "";
                      values.city = values.city
                        ? values.city
                        : !isElse
                        ? authUser.city
                        : "";
                      values.state = values.state
                        ? values.state
                        : !isElse
                        ? authUser.state
                        : "";
                      if (!values.address) {
                        return (
                          message.error("Address is required"),
                          setLoading(false)
                        );
                      }
                      if (!values.postalCode) {
                        return (
                          message.error("postal Code is required"),
                          setLoading(false)
                        );
                      }
                      if (!values.city) {
                        return (
                          message.error("city is required"), setLoading(false)
                        );
                      }
                      if (!values.state) {
                        return (
                          message.error("state is required"), setLoading(false)
                        );
                      }

                      const obj = {
                        selectedPackage: packageType,
                        ...values,
                      };

                      const formData = new FormData();

                      Object.keys(obj).forEach((key) => {
                        formData.append(key, obj[key]);
                      });

                      formData.append("detail", detail);

                      if (uploadedImage || croppedImage) {
                        formData.append("car_images", uploadedImage);
                        croppedImage &&
                          formData.append("car_images", croppedImage);
                      } else {
                        message.error("Please add an image");
                      }
                      try {
                        const res = await httpClient.post(
                          "vehicles",
                          formData,
                          {
                            "Content-Type": "multipart/form-data",
                          }
                        );
                        setPaymentModal(true);
                        setId(res.data.vehicle._id);
                        message.success("Ad Created");
                        setLoading(false);
                        setAutoThumbnail([]);
                      } catch (e) {
                        setLoading(false);
                        message.error(
                          "Make sure you provide information and media is correct"
                        );
                      }
                    }}
                  >
                    {({
                      validateForm,
                      handleChange,
                      handleBlur,
                      setTouched,
                      setFieldValue,
                    }) => (
                      <Form>
                        <ul id="progressbar">
                          {steps.map((label, index) => (
                            <li
                              key={index}
                              className={step + 1 > index ? "active" : ""}
                            >
                              {label}
                            </li>
                          ))}
                        </ul>

                        <fieldset
                          className="text-center"
                          style={{ display: step === 0 ? "block" : "none" }}
                        >
                          <div className="row mb-4 text-start">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="mb-1">
                                  Vehicle Make{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  name="make"
                                  className="form-control fc"
                                />
                                <ErrorMessage
                                  className="text-danger"
                                  name="make"
                                  component="div"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="mb-1">
                                  Vehicle Model{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  name="model"
                                  className="form-control fc"
                                />
                                <ErrorMessage
                                  className="text-danger"
                                  name="model"
                                  component="div"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="mb-1">
                                  Body Style{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  name="bodyStyle"
                                  className="form-control fc"
                                >
                                  {({ field }) => (
                                    <select
                                      className="form-control fc"
                                      value={field.value}
                                      onChange={(e) =>
                                        setFieldValue(
                                          "bodyStyle",
                                          e.target.value
                                        )
                                      }
                                    >
                                      {bodyStyle.map((style) => (
                                        <option key={style} value={style}>
                                          {style}
                                        </option>
                                      ))}
                                    </select>
                                  )}
                                </Field>
                                <ErrorMessage
                                  className="text-danger"
                                  name="bodyStyle"
                                  component="div"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="mb-1">
                                  Transmission Type{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  name="transmissionType"
                                  className="form-control fc"
                                >
                                  {({ field }) => (
                                    <select
                                      className="form-control fc"
                                      value={field.value}
                                      onChange={(e) =>
                                        setFieldValue(
                                          "transmissionType",
                                          e.target.value
                                        )
                                      }
                                    >
                                      {transmissionType.map((type) => (
                                        <option key={type} value={type}>
                                          {type}
                                        </option>
                                      ))}
                                    </select>
                                  )}
                                </Field>
                                <ErrorMessage
                                  className="text-danger"
                                  name="transmissionType"
                                  component="div"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="mb-1">
                                  Year <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="number"
                                  name="year"
                                  className="form-control fc"
                                />
                                <ErrorMessage
                                  className="text-danger"
                                  name="year"
                                  component="div"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="mb-1">
                                  Price <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="number"
                                  name="price"
                                  className="form-control fc"
                                />
                                <ErrorMessage
                                  className="text-danger"
                                  name="price"
                                  component="div"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="mb-1">
                                  Miles <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="number"
                                  name="miles"
                                  className="form-control fc"
                                />
                                <ErrorMessage
                                  className="text-danger"
                                  name="miles"
                                  component="div"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="country" className="mb-1">
                                  Country:
                                </label>
                                <Field
                                  as="select"
                                  type="string"
                                  name="country"
                                  className="form-control fc"
                                >
                                  <option value=""></option>
                                  <option value="United States">
                                    United States
                                  </option>
                                  <option value="Canada">Canada</option>
                                  <option value="New Zealand">
                                    New Zealand
                                  </option>
                                </Field>
                                <ErrorMessage
                                  name="option"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>

                            {/* start */}
                            {packageType === "premium_plus" && (
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label htmlFor="shirtSize" className="mb-1">
                                    T-Shirt Size:
                                  </label>
                                  <Field
                                    as="select"
                                    type="string"
                                    name="shirtSize"
                                    className="form-control fc"
                                  >
                                    <option value=""></option>
                                    <option value="M">Medium</option>
                                    <option value="L">Large</option>
                                    <option value="XL">X-Large</option>
                                    <option value="2XL">2X-Large</option>
                                  </Field>
                                  <ErrorMessage
                                    name="option"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                              </div>
                            )}
                            <div className="col-md-12">
                              <div className="form-group">
                                <label className="mb-1">
                                  Exterior Color{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  name="exteriorColor"
                                  className="form-control fc"
                                />
                                <ErrorMessage
                                  className="text-danger"
                                  name="exteriorColor"
                                  component="div"
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <label className="mb-1">
                                  Details <span className="text-danger">*</span>
                                </label>
                                <ReactQuill
                                  ref={quillRef}
                                  theme="snow"
                                  name="detail"
                                  value={editorHtml}
                                  onChange={handleEditorChange}
                                  // onBlur={handleBlur}
                                  style={{
                                    height: "150px",
                                  }}
                                />

                                <ErrorMessage
                                  className="text-danger"
                                  name="detail"
                                  component="div"
                                />
                              </div>
                            </div>
                            {/* end */}
                            <div style={{ marginTop: "30px" }}>
                              <div
                                style={{
                                  marginTop: isSmallScreen ? "30px" : "0px",
                                }}
                              >
                                Where is this vehicle located?
                              </div>
                              <Radio.Group onChange={onChange} value={value}>
                                <Space direction="vertical">
                                  <Radio value={1}>
                                    {`${authUser?.location}, ${
                                      authUser?.city
                                    }, ${authUser?.state}, ${
                                      authUser?.country === "Canada"
                                        ? authUser?.originalZip || ""
                                        : authUser?.postCode || ""
                                    } ${authUser?.country}`}
                                  </Radio>

                                  <Radio value={2}>Somewhere else</Radio>
                                </Space>
                              </Radio.Group>
                            </div>
                            <div>
                              {isElse && (
                                <>
                                  <div
                                    className="col-md-12"
                                    style={{ marginTop: "30px" }}
                                  >
                                    <div className="form-group">
                                      <label className="mb-1">
                                        Address{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <Field
                                        type="text"
                                        name="address"
                                        className="form-control fc mb-2"
                                      />
                                      <ErrorMessage
                                        className="text-danger"
                                        name="address"
                                        component="div"
                                      />
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <div
                                      className="col-md-6"
                                      style={{ marginRight: "16px" }}
                                    >
                                      <div className="form-group">
                                        <label className="mb-1">
                                          Postal Code{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <Field
                                          type="text"
                                          name="postalCode"
                                          className="form-control fc mb-2"
                                        />
                                        <ErrorMessage
                                          className="text-danger"
                                          name="postalCode"
                                          component="div"
                                        />
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label className="mb-1">
                                          City{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <Field
                                          type="text"
                                          name="city"
                                          className="form-control fc mb-2"
                                        />
                                        <ErrorMessage
                                          className="text-danger"
                                          name="city"
                                          component="div"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <label className="mb-1">
                                        State/Province{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <Field
                                        type="text"
                                        name="state"
                                        className="form-control fc mb-2"
                                      />
                                      <ErrorMessage
                                        className="text-danger"
                                        name="state"
                                        component="div"
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>

                            <div
                              className="col-md-6"
                              style={{ marginTop: "20px" }}
                            >
                              <div className="form-group">
                                <label className="mb-1">
                                  Mobile Number{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  name="number"
                                  className="form-control fc"
                                >
                                  {({ field, form }) => (
                                    <PatternFormat
                                      format="+1 (###) ###-####"
                                      mask="_"
                                      value={field.value} // Use Formik's field.value to ensure proper state control
                                      onValueChange={(value) =>
                                        form.setFieldValue(
                                          "number",
                                          value.formattedValue
                                        )
                                      }
                                      className="form-control fc"
                                    />
                                  )}
                                </Field>
                                <ErrorMessage
                                  className="text-danger"
                                  name="number"
                                  component="div"
                                />
                              </div>
                            </div>

                            <div
                              className="col-md-6"
                              style={{ marginTop: "20px" }}
                            >
                              <div className="form-group">
                                <label className="mb-1">
                                  Alternate Mobile Number
                                </label>
                                <Field
                                  name="altNumber"
                                  className="form-control fc"
                                >
                                  {({ field }) => (
                                    <PatternFormat
                                      format="+1 (###) ###-####"
                                      mask="_"
                                      value={field.value}
                                      onValueChange={(value) =>
                                        setFieldValue(
                                          "altNumber",
                                          value.formattedValue
                                        )
                                      }
                                      className="form-control fc"
                                    />
                                  )}
                                </Field>
                                <ErrorMessage
                                  className="text-danger"
                                  name="altNumber"
                                  component="div"
                                />
                              </div>
                            </div>
                          </div>
                          {step === 0 && (
                            <input
                              type="button"
                              name="next"
                              className="next action-button btn btn-theme-fill"
                              value="Next"
                              onClick={() => {
                                validateForm().then((errors) => {
                                  if (Object.keys(errors).length === 0) {
                                    handleNext();
                                  } else {
                                    setTouched(errors);
                                    return errors;
                                  }
                                });
                              }}
                            />
                          )}
                        </fieldset>

                        <fieldset
                          className="text-center"
                          style={{ display: step === 1 ? "block" : "none" }}
                        >
                          <div className="row mb-4 justify-content-center">
                            <br />
                            <div
                              style={{
                                textAlign: "center",
                                fontFamily: "Arial, sans-serif",
                                fontWeight: "bold",
                              }}
                            >
                              <p>
                                Please upload one photo of your vehicle,which
                                we'll use as the main image.
                              </p>
                              <br />
                              <p
                                style={{
                                  backgroundColor: "yellow",
                                  display: "inline",
                                  fontWeight: "bold",
                                }}
                              >
                                You can add more photos or change this photo
                                after you've completed your listing.
                              </p>
                            </div>{" "}
                            <Col span={24} md={24}>
                              <div style={{ marginTop: "50px" }}>
                                <div ref={dropzoneRef} className="dropzone" />
                                <div
                                  id="custom-preview-template"
                                  style={{ display: "none" }}
                                >
                                  <div className="dz-preview dz-file-preview">
                                    <div
                                      style={{ cursor: "pointer" }}
                                      className="dz-image"
                                    >
                                      <img data-dz-thumbnail alt="Thumbnail" />
                                    </div>
                                    <div className="dz-details">
                                      <div className="dz-size">
                                        <span data-dz-size></span>
                                      </div>
                                      <div className="dz-filename">
                                        <span data-dz-name></span>
                                      </div>
                                    </div>
                                    <div className="dz-progress">
                                      <span
                                        className="dz-upload"
                                        data-dz-uploadprogress
                                      ></span>
                                    </div>
                                    <div className="dz-error-message">
                                      <span data-dz-errormessage></span>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div>
                                        <i
                                          className="fas fa-regular fa-image"
                                          style={{
                                            padding: "8px",
                                            fontSize: "24px",
                                            cursor: "pointer",
                                          }}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleThumbnailImage();
                                          }}
                                        />
                                      </div>
                                      <i
                                        className="fas fa-times dz-remove"
                                        data-dz-remove
                                        style={{
                                          padding: "8px",
                                          fontSize: "24px",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </div>
                          {step === 1 && (
                            <>
                              <input
                                type="button"
                                name="previous"
                                className="previous action-button-previous btn btn-secondary btn-w-3"
                                value="Previous"
                                onClick={handlePrevious}
                              />
                              <input
                                type="button"
                                name="next"
                                className="next action-button btn btn-theme-fill ms-1"
                                value="Next"
                                onClick={() => {
                                  if (uploadedImage) {
                                    handleNext();
                                  } else {
                                    message.error("Please upload an image");
                                  }
                                }}
                              />
                            </>
                          )}
                        </fieldset>

                        <fieldset
                          className="text-center"
                          style={{ display: step === 2 ? "block" : "none" }}
                        >
                          <div className="row mb-4 text-start justify-content-center">
                            <div className="col-12 col-md-6 col-lg-6">
                              <div className="billing-box">
                                <div className="d-flex justify-content-between mb-3">
                                  <div className="text-theme">Product</div>
                                  <div className="text-theme">Subtotal</div>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <div>
                                    <Select
                                      labelInValue
                                      value={packageType}
                                      style={{
                                        width: "100%",
                                      }}
                                      onChange={handlePackageChange}
                                      options={packages.map((el) => ({
                                        value: el.type,
                                        label: `${el.title} - $${el.amount}`,
                                      }))}
                                      size="small"
                                    />
                                  </div>
                                  <div>
                                    ${" "}
                                    {
                                      packages.find(
                                        (el) => el.type === packageType
                                      )?.amount
                                    }
                                  </div>
                                </div>
                                <hr />
                                <div className="d-flex justify-content-between mb-2">
                                  <div>Subtotal</div>
                                  <div>
                                    ${" "}
                                    {
                                      packages.find(
                                        (el) => el.type === packageType
                                      )?.amount
                                    }
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <div>Tax</div>
                                  <div>$00.00</div>
                                </div>
                                <hr />
                                <div className="d-flex justify-content-between mb-2">
                                  <div className="text-theme">Total</div>
                                  <div className="text-theme">
                                    ${" "}
                                    {
                                      packages.find(
                                        (el) => el.type === packageType
                                      )?.amount
                                    }
                                  </div>
                                </div>
                                <div className="mt-4 mt-md-5">
                                  <NavLink
                                    to="https://payload.com/"
                                    target="_blank"
                                    className="text-theme underline"
                                  >
                                    What is Payload?
                                  </NavLink>
                                  <p className="mt-2">
                                    Pay via Payload, our credit processing
                                    software partner.
                                  </p>
                                  <div className="mt-4 mb-3 text-center">
                                    <button
                                      className="btn btn-theme bg-theme"
                                      disabled={loading}
                                    >
                                      Proceed To Pay
                                    </button>
                                  </div>
                                  <div className="d-flex align-items-start">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="me-2 d-inline-flex b-icon"
                                      width="49.696"
                                      height="49.696"
                                      viewBox="0 0 19.696 19.696"
                                    >
                                      <path
                                        id="information-circle-outline"
                                        d="M11.863,9.894h1.97V7.924h-1.97m.985,12.8a7.879,7.879,0,1,1,7.879-7.879,7.889,7.889,0,0,1-7.879,7.879M12.848,3A9.848,9.848,0,1,0,22.7,12.848,9.848,9.848,0,0,0,12.848,3m-.985,14.772h1.97V11.863h-1.97Z"
                                        transform="translate(-3 -3)"
                                        fill="#a7a7a7"
                                      />
                                    </svg>
                                    <div>
                                      Your personal data will be used to process
                                      your order, support your experience
                                      throughout this website, and for other
                                      purposes described in our{" "}
                                      <NavLink
                                        to="https://oppc.xponex.com/privacy-policy"
                                        className="text-theme underline"
                                      >
                                        privacy policy
                                      </NavLink>
                                      .
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {step === 2 && (
                            <>
                              <input
                                type="button"
                                name="previous"
                                className="previous action-button-previous btn btn-secondary btn-w-3"
                                value="Previous"
                                onClick={handlePrevious}
                              />
                            </>
                          )}
                        </fieldset>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageLayout>
      <>
        {/* Delete Modal */}
        <div
          className="modal fade"
          id="deleteModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body text-center">
                <button
                  type="button"
                  className="btn-close modal-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
                <div className="mb-3 mt-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={56}
                    height={56}
                    fill="currentColor"
                    className="bi bi-x-circle text-danger"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </div>
                <h2 className="text-theme bold h2-title mb-3">Are you sure?</h2>
                <p>
                  Do you really want to delete these records? This process
                  cannot be undone.
                </p>
                <div className=" mt-4 mt-md-4 mb-4">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>{" "}
                  &nbsp;&nbsp;
                  <button type="button" className="btn btn-danger">
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>

      <PaymentModal
        visible={paymentModal}
        onCancel={() => {
          setPaymentModal(false);
          navigate(`/thanksforlisting/${id}`);
        }}
        onOk={handleOk}
        user={authUser}
        showSkipButton
        forAddVehicle
        amount={packages.find((el) => el.type === packageType)?.amount}
      />
      <Demo
        imageToCrop={imageToCrop}
        {...{ open, setOpen, croppedImage, setCroppedImage }}
      />
    </>
  );
};

export default ListVehicleStep;
