import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";

import store from "./redux/store";
import { HelmetProvider } from "react-helmet-async";
// Suppress all warnings
//console.warn = () => {};
//console.error = () => {};

const root = ReactDOM.createRoot(document.getElementById("root"));
console.error = () => {};
console.warn = () => {};
root.render(
  // <React.StrictMode>
  <HelmetProvider>
    <Provider store={store}>
      <App />
    </Provider>
  </HelmetProvider>
  // </React.StrictMode>
);
