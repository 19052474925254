import React, { Component } from "react";
import PageLayout from "../../Layout/PageLayout";
export class ListVehicle extends Component {
  render() {
    return (
      <>
        <PageLayout
          type="website"
          imageWidth="1920"
          title="Checkout"
          url="https://opposingcylinders.com/"
          description="Find your next collector car or list your vehicle for sale on opposingcylinders.com today!"
          image="https://opposingcylinders.com/static/media/hero-img.0b755dc30efdb71d6784.jpg"
        >
          <header>
            <h1>Checkout Agreement</h1>
            <p>
              By proceeding to list your vehicle for sale on
              opposingcylinders.com, you agree to the following terms and
              conditions, privacy policy, and disclaimers. Please read this
              agreement carefully before continuing.
            </p>
          </header>

          <section>
            <h2>Terms and Conditions</h2>
            <ol>
              <li>
                <h3>Service Description:</h3>
                <p>
                  opposingcylinders.com provides an online platform for Sellers
                  to list vehicles for sale and for buyers to contact the
                  selling contact.{" "}
                  <b>We do not own, buy, or sell listed vehicles.</b> Our
                  service is limited to facilitating connecting buyers and
                  sellers.
                </p>
              </li>
              <li>
                <h3>Seller Responsibilities:</h3>
                <p>
                  As a Seller, you agree to provide accurate and truthful
                  information about the vehicle you are listing, including its
                  condition, history, and any other relevant details.{" "}
                  <b>
                    You are solely responsible for the content of your listing
                    and for any transactions or disputes that arise from your
                    use of our services.
                  </b>
                </p>
              </li>
              <li>
                <h3>Fees and Payments:</h3>
                <p>
                  Listing a vehicle on opposingcylinders.com is subject to an
                  initial vehicle listing payment fee, and the package you
                  choose when listing your vehicle may also make you subject to
                  commission fees at time of your vehicle's sale. All financial
                  transactions must comply with our payment terms.
                </p>
              </li>
              <li>
                <h3>Dispute Resolution:</h3>
                <p>
                  opposingcylinders.com provides a platform for Sellers and
                  Buyers to connect, but is not involved in the actual
                  transaction. In the event of a dispute, Sellers and Buyers are
                  encouraged to resolve their issues amicably. Opposing
                  Cylinders reserves the right, but has no obligation, to
                  mediate disputes.
                </p>
              </li>
            </ol>
          </section>

          <section>
            <h2>Privacy Policy</h2>
            <p>
              Your privacy is important to us. Our Privacy Policy outlines how
              we collect, use, and protect your personal information and respect
              your privacy rights. By agreeing to these terms, you also consent
              to our collection, use, and sharing of your information as
              described in our Privacy Policy.
            </p>
          </section>

          <section>
            <h2>Disclaimer</h2>
            <p>
              Opposing Cylinders does not guarantee the accuracy of vehicle
              listings, the quality, safety, or legality of the vehicles
              advertised, the truth or accuracy of users' content, or the
              ability of sellers to sell vehicles or buyers to buy vehicles. We
              are not responsible for any losses or damages arising from your
              use of our platform.
            </p>
          </section>
        </PageLayout>
      </>
    );
  }
}

export default ListVehicle;
