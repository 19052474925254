// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Fonts/Futura-Bold.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* In your CSS file (e.g., App.css) */
@font-face {
    font-family: 'Futura Bold';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
    font-weight: bold;  
    font-display: swap;
  }
  
  body {
    font-family: 'Futura Bold', sans-serif;
  }


`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,qCAAqC;AACrC;IACI,0BAA0B;IAC1B,+DAAsD;IACtD,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,sCAAsC;EACxC","sourcesContent":["/* In your CSS file (e.g., App.css) */\n@font-face {\n    font-family: 'Futura Bold';\n    src: url('./Fonts/Futura-Bold.otf') format('truetype');\n    font-weight: bold;  \n    font-display: swap;\n  }\n  \n  body {\n    font-family: 'Futura Bold', sans-serif;\n  }\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
