import React from "react";

const ShareModal = ({ isOpen, onClose, shareUrl }) => {
  const facebookShareLink = `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`;
  const twitterShareLink = `https://twitter.com/intent/tweet?url=${shareUrl}&text=Check%20this%20out!`;
  const whatsAppShareLink = `https://api.whatsapp.com/send?text=Check%20this%20out!%20${shareUrl}`;
  const telegramShareLink = `https://telegram.me/share/url?url=${shareUrl}&text=Check%20this%20out!`;
  if (!isOpen) {
    return null;
  }
  return (
    <div
      className={`modal fade ${isOpen && "show"}`}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      id="shareModal"
      tabIndex={-1}
      style={{ display: "block" }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body text-center">
            <button
              type="button"
              className="btn-close modal-close"
              aria-label="Close"
              onClick={onClose}
            />
            <h2 className="text-theme bold h3-title mb-3 mt-4">
              Do you like this?
              <br /> Share with your friends!
            </h2>
            <p>Share this link via</p>
            <ul className="icons">
              <a
                href={facebookShareLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-facebook" />
              </a>
              <a
                href={twitterShareLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-twitter" />
              </a>
              <a href={"#"} target="_blank" rel="noopener noreferrer">
                <i className="fa fa-instagram" />
              </a>
              <a
                href={whatsAppShareLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-whatsapp" />
              </a>
              <a
                href={telegramShareLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-telegram" />
              </a>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareModal;
