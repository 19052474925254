import React, { Component } from "react";
import PageLayout from "../../Layout/PageLayout";
export class ListVehicle extends Component {
  render() {
    return (
      <>
        <PageLayout
          type="website"
          imageWidth="1920"
          title="Privacy Policy"
          url="https://opposingcylinders.com/"
          description="Find your next collector car or list your vehicle for sale on opposingcylinders.com today!"
          image="https://opposingcylinders.com/static/media/hero-img.0b755dc30efdb71d6784.jpg"
        >
          <header>
            <h1>Privacy Policy</h1>
          </header>

          <section>
            <h2>What personal data we collect and why we collect it</h2>
            <h3>Account & Contact Information</h3>
            <p>
              When you create an account with us, we collect personal
              information to enhance your user experience and improve our
              services. This information includes but is not limited to:
            </p>
            <ul>
              <li>
                Names: We collect your first and last name to personalize your
                experience on our platform.
              </li>
              <li>
                Addresses: We gather your physical address to provide
                location-specific services, enable delivery features if
                applicable, and comply with local regulations.
              </li>
              <li>
                Contact Information: Your email address and phone number are
                collected to communicate important account-related notices,
                service updates, and for customer support purposes.
              </li>
            </ul>
            <p>
              All user information will remain private unless you choose to
              share any information with other users of this site in any part of
              a vehicle listing.
            </p>

            <h3>Billing Information</h3>
            <p>
              All sensitive credit card and payment data is encrypted, and
              access to that information is restricted. No credit card
              information is stored directly on our server. PayLoad is used for
              credit card transactions; you may view their privacy policy at{" "}
              <a href="https://payload.co/privacy">
                https://payload.co/privacy
              </a>
              .
            </p>

            <h3>Media Metadata</h3>
            <p>
              If you upload images to the website, you should avoid uploading
              images with embedded location data (EXIF GPS) included. Visitors
              to the website can download and extract any location data from
              images on the website.
            </p>

            <h3>Contact Forms</h3>
            <p>
              If you fill out a contact form or request password form, that
              information will be used to contact you at the provided line of
              contact.
            </p>

            <h3>Cookies</h3>
            <p>
              If you have an account and you log in to this site, we will set a
              temporary cookie to determine if your browser accepts cookies.
              This cookie contains no personal data and is discarded when you
              close your browser.
            </p>
            <p>
              When you log in, we will also set up several cookies to save your
              login information and your screen display choices. Login cookies
              last for two days and screen options cookies last for a year. If
              you log out of your account, the login cookies will be removed.
            </p>
            <p>
              If you edit or publish a vehicle listing, an additional cookie
              will be saved in your browser. This cookie includes no personal
              data and simply indicates the post ID of the article you just
              edited.
            </p>

            <h3>Embedded Content from Other Websites</h3>
            <p>
              Articles on this site may include embedded content (e.g. videos,
              images, articles, etc.). Embedded content from other websites
              behaves in the exact same way as if the visitor has visited the
              other website.
            </p>
            <p>
              These websites may collect data about you, use cookies, embed
              additional third-party tracking, and monitor your interaction with
              that embedded content, including tracking your interaction with
              the embedded content if you have an account and are logged in to
              that website.
            </p>
            <p>
              Analytics information is collected by all users of
              https://opposingcylinders.com/
            </p>

            <h3>How Long we Retain your Data</h3>
            <p>
              If you post a listing, your listing, any images contained, and
              their metadata are retained indefinitely
            </p>
            <p>
              For users that register on our website, we also store the personal
              information they provide in their user profile. All users can see,
              edit, or delete their own personal information at any time.
              Website administrators can also see and edit that information.
            </p>

            <h3>What Rights you Have Over your Data</h3>
            <p>
              If you have an account on this site, you can request to receive an
              exported file of the personal data we hold about you, including
              any data you have provided to us. You can also request that we
              erase any personal data we hold about you. This does not include
              any data we are obliged to keep for administrative, legal, or
              security purposes.
            </p>

            <h3>What Data Breach Procedures we Have in Place</h3>
            <p>
              Members will be notified of any data breaches and their estimated
              severity.
            </p>

            <h3>
              What Automated Decision Making and/or Profiling we do with User
              Data
            </h3>
            <p>
              Some content may be filtered and made available to registered and
              paid members only.
            </p>

            <h3>Industry Regulatory Disclosure Requirements</h3>
            <p>
              If you disagree with any portion of this policy, you may
              unsubscribe and request your data be reasonably deleted.
            </p>
          </section>
        </PageLayout>
      </>
    );
  }
}

export default ListVehicle;
