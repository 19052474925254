import React, { useEffect } from "react";
import { httpClient } from "../util/Api";
import { useNavigate } from "react-router-dom";

export const Success = () => {
  const navigate = useNavigate();
  useEffect(() => {
    httpClient.post("payments/success").then(() => {
      navigate("/");
    });
  }, [navigate]);
  return <div></div>;
};
