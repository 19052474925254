export const blobToBase64 = async (blob, filename) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
      const link = document.createElement("a");
      link.href = base64data;
      link.download = filename;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
};
