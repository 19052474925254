const getDeviceType = () => {
  const userAgent = navigator.userAgent;

  // Check for tablet devices

  // Check for iPhone or iOS devices
  if (/iPhone|iPad|iPod/i.test(userAgent)) {
    return "iPhone/iOS";
  } else if (/tablet|ipad|playbook|silk|Gecko/i.test(userAgent)) {
    return "Tablet";
  }
  // Check for other mobile devices
  else if (
    /Mobile|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/i.test(
      userAgent
    )
  ) {
    return "Mobile";
  }
  // Assume desktop for other cases
  else {
    return "Desktop";
  }
};

export default getDeviceType;
