import "./Styles/custome.css";
import "./Styles/responsive.css";
import "./Styles/font-awesome.css";
import "./Styles/font-awesome.min.css";
import MainRoutes from "./Routes";
import { useEffect } from "react";
import { httpClient } from "./util/Api";
import { AuthProvider } from "./authentication";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./util/ScrollToTop";
import "./index.css";

function App() {
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      httpClient.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
  }, []);
  return (
    <AuthProvider>
      <BrowserRouter>
        <ScrollToTop />
        <MainRoutes />
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
