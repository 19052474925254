import React, { Component } from "react";
import { NavLink } from "react-router-dom";
export class BillingCard extends Component {
  render() {
    return (
      <>
        <div className="billing-box">
          <div className="d-flex justify-content-between mb-3">
            <div className="text-theme">Product</div>
            <div className="text-theme">Subtotal</div>
          </div>
          <div className="d-flex justify-content-between">
            <div>Membership × 1</div>
            <div>$15.00</div>
          </div>
          <hr />
          <div className="d-flex justify-content-between mb-2">
            <div>Subtotal</div>
            <div>$15.00</div>
          </div>
          <div className="d-flex justify-content-between">
            <div>Tax</div>
            <div>$00.00</div>
          </div>
          <hr />
          <div className="d-flex justify-content-between mb-2">
            <div className="text-theme">Total</div>
            <div className="text-theme">$10.00</div>
          </div>
          <div className="mt-4 mt-md-5">
            <NavLink
              to="https://payload.com/"
              target="_blank"
              className="text-theme underline"
            >
              What is Payload?
            </NavLink>
            <p className="mt-2">
              Pay via Payload, our credit processing software partner.
            </p>
            <div className="mt-4 mb-3 text-center">
              <button className="btn btn-theme bg-theme">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="me-2"
                  width="14.878"
                  height="17.575"
                  viewBox="0 0 14.878 17.575"
                >
                  <path
                    id="paypal"
                    d="M8.818,12.392c-.136.744-.674,4.213-.833,5.193-.012.07-.039.1-.116.1H4.978a.471.471,0,0,1-.469-.539L6.78,2.73a.788.788,0,0,1,.775-.655c5.9,0,6.4-.143,7.906.442C17.79,3.42,18,5.6,17.166,7.955c-.833,2.426-2.809,3.468-5.429,3.5-1.682.027-2.694-.272-2.918.938Zm9.522-5.577c-.07-.05-.1-.07-.116.05a9.107,9.107,0,0,1-.341,1.3c-1.547,4.41-5.832,4.027-7.925,4.027a.39.39,0,0,0-.422.364C8.661,18,8.486,19.135,8.486,19.135a.412.412,0,0,0,.411.5h2.461a.7.7,0,0,0,.675-.577c.027-.209-.043.236.558-3.538.179-.853.554-.763,1.135-.763,2.752,0,4.9-1.116,5.537-4.352.252-1.349.179-2.767-.922-3.589Z"
                    transform="translate(-4.504 -2.06)"
                    fill="#fbfdff"
                  />
                </svg>
                Procced To PayPal
              </button>
            </div>
            <div className="d-flex align-items-start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="me-2 d-inline-flex b-icon"
                width="49.696"
                height="49.696"
                viewBox="0 0 19.696 19.696"
              >
                <path
                  id="information-circle-outline"
                  d="M11.863,9.894h1.97V7.924h-1.97m.985,12.8a7.879,7.879,0,1,1,7.879-7.879,7.889,7.889,0,0,1-7.879,7.879M12.848,3A9.848,9.848,0,1,0,22.7,12.848,9.848,9.848,0,0,0,12.848,3m-.985,14.772h1.97V11.863h-1.97Z"
                  transform="translate(-3 -3)"
                  fill="#a7a7a7"
                />
              </svg>
              <div>
                Your personal data will be used to process your order, support
                your experience throughout this website, and for other purposes
                described in our{" "}
                <NavLink
                  to="https://oppc.xponex.com/privacy-policy"
                  className="text-theme underline"
                >
                  privacy policy
                </NavLink>
                .
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default BillingCard;
