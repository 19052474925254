export const packages = [
  {
    type: "basic",
    title: "Basic Car Listing",
    amount: "99.00",
  },
  {
    type: "premium",
    title: "Premium Car Listing",
    amount: "150.00",
  },
  {
    type: "premium_plus",
    title: "Premium Plus Car Listing",
    amount: "200.00",
  },
];
