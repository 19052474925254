import { useNavigate } from "react-router-dom";
import Slider from "react-slick"; // Import the Slick Slider component

import "./hero.css"; // Your custom CSS
import img from "../../Assets/Images/membership-section-revised.jpg";
import logo1 from "../../Assets/Images/vendor-benefits/aceofshades.jpg";
import logo2 from "../../Assets/Images/vendor-benefits/california.jpg";
import logo3 from "../../Assets/Images/vendor-benefits/greeningauto.jpg";
import logo4 from "../../Assets/Images/vendor-benefits/precision-hotrods-logo.png";
import logo5 from "../../Assets/Images/vendor-benefits/ricks-logo.png";
import logo6 from "../../Assets/Images/vendor-benefits/socal-speedshop-logo.png";
import logo7 from "../../Assets/Images/vendor-benefits/sweetpatina.jpg";
import logo8 from "../../Assets/Images/vendor-benefits/vintagepro.png";
import logo9 from "../../Assets/Images/vendor-benefits/hotrod-haven-logo.png";
import bitchin from "../../Assets/Images/vendor-benefits/bitchin.jpg";
import murray from "../../Assets/Images/vendor-benefits/murray.jpg";
import port from "../../Assets/Images/vendor-benefits/port-city-hot-rods.jpeg";
import speedanddesign from "../../Assets/Images/vendor-benefits/speedanddesign.jpg";
import fitech from "../../Assets/Images/vendor-benefits/fitech.jpg";
import pete from "../../Assets/Images/vendor-benefits/pete.jpg";
const BecomeMember = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/membership");
  };

  // Slick settings for the carousel
  const settings = {
    dots: false, // Hide dots below the carousel
    infinite: true, // Enable infinite scrolling
    speed: 500, // Transition speed
    slidesToShow: 5, // Show 4 logos at a time
    slidesToScroll: 1, // Scroll one logo at a time
    autoplay: true, // Enable auto-scrolling
    autoplaySpeed: 3000, // Auto-scrolling interval (3 seconds)
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div>
      <div className="hero-container">
        <img src={img} alt="Become a member" className="hero-image" />
        <div className="hero-content">
          <h1 className="hero-title">
            Become a Member of
            <br /> Opposing Cylinders!
          </h1>
          <p className="hero-description">
            Club Members get FIRST ACCESS to premium vehicle listings and have
            access to our exclusive member vendor-partner discounts!
            <br />
            Crew Members receive all the perks of Club Members, receive an
            Opposing Cylinders swag pack, are entered into Opposing Cylinders
            giveaway events, and more.
          </p>
          <div className="hero-button-wrapper">
            <button
              onClick={handleClick}
              className="btn btn-theme-fill rounded mb-4 px-2"
            >
              Become a Member Today!
            </button>
          </div>
        </div>
      </div>

      {/* Logo Carousel */}
      <div className="logo-carousel-wrapper">
        <Slider {...settings}>
          <div style={{ textAlign: "center", marginTop: "30px" }}>
            <img src={logo1} alt="Logo 1" />
            <h6
              className="h4-title text-theme"
              style={{ marginTop: "32px", marginLeft: "10px" }}
            >
              Ace of Shades
            </h6>
            <ul style={{ marginTop: "10px", padding: 0 }}>
              <li>5% off on any order</li>
            </ul>
          </div>
          <div style={{ textAlign: "center", marginTop: "30px" }}>
            <img src={logo2} alt="Logo 2" />
            <h6 className="h4-title text-theme" style={{ marginTop: "53px" }}>
              California Car Cover
            </h6>
            <ul style={{ marginTop: "10px", padding: 0 }}>
              <li>10% off on any order</li>
            </ul>
          </div>
          <div style={{ textAlign: "center", marginTop: "30px" }}>
            <img src={logo3} alt="Logo 3" />
            <h6 className="h4-title text-theme" style={{ marginTop: "42px" }}>
              Greening Auto
            </h6>
            <ul style={{ marginTop: "10px", padding: 0 }}>
              <li>10% off on all products</li>
            </ul>
          </div>
          <div style={{ textAlign: "center", marginTop: "30px" }}>
            <img src={logo4} alt="Logo 4" />
            <h6 className="h4-title text-theme" style={{ marginTop: "39px" }}>
              Precision Hot Rods and Fabrication
            </h6>
            <ul style={{ marginTop: "10px", padding: 0 }}>
              <li>5% off on all new chassis orders</li>
            </ul>
          </div>
          <div style={{ textAlign: "center", marginTop: "30px" }}>
            <img src={logo5} alt="Logo 5" style={{ marginTop: "26px" }} />
            <h6 className="h4-title text-theme" style={{ marginTop: "59px" }}>
              Rick’s Tanks
            </h6>
            <ul style={{ marginTop: "10px", padding: 0 }}>
              <li>10% off on all gas tank items</li>
            </ul>
          </div>
          <div style={{ textAlign: "center", marginTop: "30px" }}>
            <img src={logo6} alt="Logo 6" style={{ marginTop: "26px" }} />
            <h6 className="h4-title text-theme" style={{ marginTop: "59px" }}>
              So-Cal Speed Shop
            </h6>
            <ul style={{ marginTop: "10px", padding: 0 }}>
              <li>10% off on all Apparel</li>
              <li>$250 off on any perimeter chassis</li>
              <li>$500 off any complete roller chassis</li>
            </ul>
          </div>
          <div style={{ textAlign: "center", marginTop: "30px" }}>
            <img src={logo7} alt="Logo 7" />
            <h6 className="h4-title text-theme" style={{ marginTop: "25px" }}>
              Sweet Patina
            </h6>
            <ul style={{ marginTop: "10px", padding: 0 }}>
              <li>5% off on any order</li>
            </ul>
          </div>
          <div style={{ textAlign: "center", marginTop: "30px" }}>
            <img src={logo8} alt="Logo 8" />
            <h6 className="h4-title text-theme" style={{ marginTop: "32px" }}>
              Vintage Pro
            </h6>
            <ul style={{ marginTop: "10px", padding: 0 }}>
              <li>5% off on any order</li>
            </ul>
          </div>
          <div style={{ textAlign: "center", marginTop: "30px" }}>
            <img src={logo9} alt="Logo 9" />
            <h6 className="h4-title text-theme" style={{ marginTop: "29px" }}>
              Hotrod Haven LLC
            </h6>
            <ul style={{ marginTop: "10px", padding: 0 }}>
              <li>
                10% off transport/enclosed shipping, up to $200 for all Crew
                Members only.
              </li>
            </ul>
          </div>

          <div style={{ textAlign: "center", marginTop: "60px" }}>
            <img style={{ marginTop: "30px" }} src={bitchin} alt="Logo 9" />
            <h6 className="h4-title text-theme" style={{ marginTop: "17px" }}>
              Bitchin' Parts
            </h6>
            <ul style={{ marginTop: "10px", padding: 0 }}>
              <li>Members receive a 5% discount on all parts.</li>
            </ul>
          </div>
          <div style={{ textAlign: "center", marginTop: "45px" }}>
            <img src={murray} alt="Logo 9" />
            <h6 className="h4-title text-theme" style={{ marginTop: "49px" }}>
              Murray Kustom Rods
            </h6>
            <ul style={{ marginTop: "10px", padding: 0 }}>
              <li>Members get 5% off on all parts.</li>
            </ul>
          </div>
          <div style={{ textAlign: "center", marginTop: "39px" }}>
            <img src={speedanddesign} alt="Logo 9" />
            <h6 className="h4-title text-theme" style={{ marginTop: "49px" }}>
              Speed And Design
            </h6>
            <ul style={{ marginTop: "10px", padding: 0 }}>
              <li>
                Members get various discount rates on full interiors, seat
                coverings, custom key chains, and other specialty items.
              </li>
            </ul>
          </div>

          <div style={{ textAlign: "center", marginTop: "39px" }}>
            <img src={port} alt="Logo 9" />
            <h6 className="h4-title text-theme" style={{ marginTop: "22px" }}>
              Port City Hot Rods
            </h6>
            <ul style={{ marginTop: "0px", padding: 0 }}>
              <li>10% off Offenhauser parts</li>
              <li>10% off on all apparel</li>
            </ul>
          </div>

          <div style={{ textAlign: "center", marginTop: "49px" }}>
            <img
              style={{ width: "170px", marginTop: "40px", marginLeft: "18px" }}
              src={fitech}
              alt="Logo 9"
            />
            <h6 className="h4-title text-theme" style={{ marginTop: "55px" }}>
              FiTech Fuel Injection
            </h6>
            <ul style={{ marginTop: "0px", padding: 0 }}>
              <li>
                10% any purchase. (Excludes factory refurbished or discontinued
                products. Not eligible for use with other promotions, sales, or
                discount codes).
              </li>
            </ul>
          </div>

          <div style={{ textAlign: "center", marginTop: "45px" }}>
            <img src={pete} style={{ marginTop: "29px" }} alt="Logo 9" />
            <h6 className="h4-title text-theme" style={{ marginTop: "50px" }}>
              Pete and Jakes Hot Rod Parts
            </h6>
            <ul style={{ marginTop: "0px", padding: 0 }}>
              <li>
                5% off on all orders, including apparel, suspension parts, and
                chassis.
              </li>
            </ul>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default BecomeMember;
