const ProfileData = [
  {
    firstName: "John Deo",
    number: "0123456789",
    lastName: "last Name",
    country: "Country",
    company: "company",
    address: "address",
    apartment: "apartment",
    suburb: "suburb",
    state: "state",
    postCode: "code",
    email: "email",
    purchased: [
      {
        packageName: "Basic",
        date: "03-09-2005",
      },
      {
        packageName: "Premium",
        date: "03-09-2005",
      },
    ],
  },
];
export default ProfileData;
