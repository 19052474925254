// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/CustomLoader.css */
.loader-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Lighter black blur effect */
  z-index: 1999; /* Ensures the loader is above other content */
}

.loader-container {
  display: flex;
  align-items: center;
  font-family: Arial, sans-serif;
  font-size: 24px;
  color: #fff; /* White text color for contrast */
}

.loader-text {
  white-space: nowrap;
  overflow: hidden;
}

.loader-dots {
  display: inline-block;
  position: relative;
}

.loader-dots span {
  animation: blink 1s infinite step-start;
  font-weight: bold;
}

.loader-dots span:nth-child(2) {
  animation-delay: 0.2s;
}

.loader-dots span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Component/CustomLoader/CustomLoader.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oCAAoC,EAAE,8BAA8B;EACpE,aAAa,EAAE,8CAA8C;AAC/D;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,eAAe;EACf,WAAW,EAAE,kCAAkC;AACjD;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,uCAAuC;EACvC,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF","sourcesContent":["/* src/CustomLoader.css */\n.loader-wrapper {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.5); /* Lighter black blur effect */\n  z-index: 1999; /* Ensures the loader is above other content */\n}\n\n.loader-container {\n  display: flex;\n  align-items: center;\n  font-family: Arial, sans-serif;\n  font-size: 24px;\n  color: #fff; /* White text color for contrast */\n}\n\n.loader-text {\n  white-space: nowrap;\n  overflow: hidden;\n}\n\n.loader-dots {\n  display: inline-block;\n  position: relative;\n}\n\n.loader-dots span {\n  animation: blink 1s infinite step-start;\n  font-weight: bold;\n}\n\n.loader-dots span:nth-child(2) {\n  animation-delay: 0.2s;\n}\n\n.loader-dots span:nth-child(3) {\n  animation-delay: 0.4s;\n}\n\n@keyframes blink {\n  0% {\n    opacity: 0;\n  }\n  50% {\n    opacity: 1;\n  }\n  100% {\n    opacity: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
