import { createStore, combineReducers } from "redux";
import { browseVehiclesReducer } from "./reducers/browsevehiclesReducer";

const rootReducer = combineReducers({
  browseVehicles: browseVehiclesReducer,
  // other reducers can be added here
});

const store = createStore(rootReducer);

export default store;
