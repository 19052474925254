import React, { useEffect } from "react";
import Header from "../../Component/Header";
import Footer from "../../Component/Footer";

const PageLayout = ({
  children,
  title,
  description,
  image,
  url,
  type,
  imageWidth,
}) => {
  useEffect(() => {
    document.title = `${title} - Opposing Cylinders`;

    // Update description meta tag
    const descriptionMetaTag = document.querySelector(
      'meta[name="description"]'
    );
    if (descriptionMetaTag) {
      descriptionMetaTag.setAttribute("content", description);
    } else {
      const meta = document.createElement("meta");
      meta.name = "description";
      meta.content = description;
      document.head.appendChild(meta);
    }

    // Update image meta tag
    const imageMetaTag = document.querySelector('meta[name="image"]');
    if (imageMetaTag) {
      imageMetaTag.setAttribute("content", image);
    } else {
      const meta = document.createElement("meta");
      meta.name = "image";
      meta.content = image;
      document.head.appendChild(meta);
    }
    const urlmetatag = document.querySelector('meta[name="url"]');
    if (urlmetatag) {
      urlmetatag.setAttribute("content", url);
    } else {
      const meta = document.createElement("meta");
      meta.name = "url";
      meta.content = url;
      document.head.appendChild(meta);
    }

    const typemetatag = document.querySelector('meta[name="type"]');
    if (typemetatag) {
      typemetatag.setAttribute("content", type);
    } else {
      const meta = document.createElement("meta");
      meta.name = "type";
      meta.content = type;
      document.head.appendChild(meta);
    }

    const imageWidthmetatag = document.querySelector(
      'meta[name="image:width"]'
    );
    if (imageWidthmetatag) {
      imageWidthmetatag.setAttribute("content", imageWidth);
    } else {
      const meta = document.createElement("meta");
      meta.name = "image:width";
      meta.content = imageWidth;
      document.head.appendChild(meta);
    }
  }, [title, description, image, url, type, imageWidth]);
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
};

export default PageLayout;
