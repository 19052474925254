import React, { Component } from "react";
import Image from "../../Assets/Images/membership.png";
export class CartCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 1, // Set the initial count to 1
    };
  }

  incrementCount = () => {
    this.setState((prevState) => ({
      count: prevState.count + 1,
    }));
  };

  decrementCount = () => {
    if (this.state.count > 1) {
      this.setState((prevState) => ({
        count: prevState.count - 1,
      }));
    }
  };

  render() {
    return (
      <>
        {/* row */}
        <div>
          <div>
            <div className="justify-content-center">
              <div data-bs-toggle="modal" data-bs-target="#deleteModal">
                <span
                  className="icons-hover"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title=""
                  data-bs-original-title="Delete"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16.037"
                    className="share-icon-white cr"
                    height="19.738"
                    viewBox="0 0 16.037 19.738"
                  >
                    <path
                      id="trash"
                      d="M16.5,6.067h3.7A1.165,1.165,0,0,1,21.437,7.3V8.534H5.4V7.3A1.236,1.236,0,0,1,6.634,6.067h3.7a3.161,3.161,0,0,1,6.168,0Zm-4.934,0h3.7a2,2,0,0,0-3.7,0Zm-4.934,3.7H20.2l-1.11,12.459a1.217,1.217,0,0,1-1.234,1.11H8.977a1.312,1.312,0,0,1-1.234-1.11Z"
                      transform="translate(-5.4 -3.6)"
                      fill="#f15757"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
          <div>
            <img src={Image} className="img-fluid cart-img" alt="img" />
          </div>
          <div>Membership</div>
          <div>%15.00</div>
          <div>
            <div className="number">
              <span className="minus" onClick={this.decrementCount}>
                -
              </span>

              <span className="mx-3"> {this.state.count}</span>
              <span className="plus" onClick={this.incrementCount}>
                +
              </span>
            </div>
          </div>
          <div>%15.00</div>
        </div>
      </>
    );
  }
}

export default CartCard;
