// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;charset=utf8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27%23395C93%27 viewBox=%270 0 8 8%27%3E%3Cpath d=%27M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z%27/%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("data:image/svg+xml;charset=utf8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27%23395C93%27 viewBox=%270 0 8 8%27%3E%3Cpath d=%27M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z%27/%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backArrowBox{
    height: 45px;
    height: 45px;
    cursor: pointer;
    position: absolute;
    top: 12px;
    left: 12px;
    z-index: 9;
    background-color: #fff;
    border-radius: 50%;
    padding: 8px;
}
.backArrowImage{
    width: 100%;
    height: 100%;
}

/* .carousel .carousel-indicators li {  background-color: black; }
.carousel .carousel-indicators li.active { background-color: var(--primary); } */

.vehicleSliderImg{
    max-width: 100%;
    max-height: 60vh;
    margin: auto;
}


.carousel-control-prev:hover,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-next:focus {
  background-color: transparent;
  opacity: 1!important; /* Ensure full visibility */
}
.carousel-control-next{
    opacity: 1!important;
}
.carousel-control-prev{
    opacity: 1!important;
}
.carousel-control-prev-icon {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) !important;
   }
   
   .carousel-control-next-icon {
     background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) !important;
   }
`, "",{"version":3,"sources":["webpack://./src/Pages/VehicleDetail/styles.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,UAAU;IACV,sBAAsB;IACtB,kBAAkB;IAClB,YAAY;AAChB;AACA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;gFACgF;;AAEhF;IACI,eAAe;IACf,gBAAgB;IAChB,YAAY;AAChB;;;AAGA;;;;EAIE,6BAA6B;EAC7B,oBAAoB,EAAE,2BAA2B;AACnD;AACA;IACI,oBAAoB;AACxB;AACA;IACI,oBAAoB;AACxB;AACA;IACI,oEAA8N;GAC/N;;GAEA;KACE,oEAA8N;GAChO","sourcesContent":[".backArrowBox{\n    height: 45px;\n    height: 45px;\n    cursor: pointer;\n    position: absolute;\n    top: 12px;\n    left: 12px;\n    z-index: 9;\n    background-color: #fff;\n    border-radius: 50%;\n    padding: 8px;\n}\n.backArrowImage{\n    width: 100%;\n    height: 100%;\n}\n\n/* .carousel .carousel-indicators li {  background-color: black; }\n.carousel .carousel-indicators li.active { background-color: var(--primary); } */\n\n.vehicleSliderImg{\n    max-width: 100%;\n    max-height: 60vh;\n    margin: auto;\n}\n\n\n.carousel-control-prev:hover,\n.carousel-control-next:hover,\n.carousel-control-prev:focus,\n.carousel-control-next:focus {\n  background-color: transparent;\n  opacity: 1!important; /* Ensure full visibility */\n}\n.carousel-control-next{\n    opacity: 1!important;\n}\n.carousel-control-prev{\n    opacity: 1!important;\n}\n.carousel-control-prev-icon {\n    background-image: url(\"data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23395C93' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E\") !important;\n   }\n   \n   .carousel-control-next-icon {\n     background-image: url(\"data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23395C93' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E\") !important;\n   }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
