// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  /* justify-content: space-between; */
}

.cropper-container {
  position: relative;
  width: 250px;
  height: 250px;
  background: #f3f3f3;
  margin-top: 20px;
}

.icon-wrapper {
  position: relative;
  top: -50%;
  left: -30%;
  transform: translate(50%, 50%);
  padding: 0px 30px;
  width: 80%;
  margin-bottom: 30px;
}

.icon-wrapper .anticon {
  position: absolute;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
}

.icon-wrapper .icon-wrapper-active {
  color: rgba(0, 0, 0, 0.45);
}

.icon-wrapper .anticon:first-child {
  left: 0;
  height: 16px;
  top: -2px;
}

.icon-wrapper .anticon:last-child {
  right: 0;
  font-size: 24px;
  top: -6px !important;
}
`, "",{"version":3,"sources":["webpack://./src/Component/UploadImage/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,oCAAoC;AACtC;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,8BAA8B;EAC9B,iBAAiB;EACjB,UAAU;EACV,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,0BAA0B;EAC1B,cAAc;AAChB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,OAAO;EACP,YAAY;EACZ,SAAS;AACX;;AAEA;EACE,QAAQ;EACR,eAAe;EACf,oBAAoB;AACtB","sourcesContent":[".container-wrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  align-items: center;\n  justify-content: center;\n  margin-bottom: 20px;\n  /* justify-content: space-between; */\n}\n\n.cropper-container {\n  position: relative;\n  width: 250px;\n  height: 250px;\n  background: #f3f3f3;\n  margin-top: 20px;\n}\n\n.icon-wrapper {\n  position: relative;\n  top: -50%;\n  left: -30%;\n  transform: translate(50%, 50%);\n  padding: 0px 30px;\n  width: 80%;\n  margin-bottom: 30px;\n}\n\n.icon-wrapper .anticon {\n  position: absolute;\n  color: rgba(0, 0, 0, 0.25);\n  line-height: 1;\n}\n\n.icon-wrapper .icon-wrapper-active {\n  color: rgba(0, 0, 0, 0.45);\n}\n\n.icon-wrapper .anticon:first-child {\n  left: 0;\n  height: 16px;\n  top: -2px;\n}\n\n.icon-wrapper .anticon:last-child {\n  right: 0;\n  font-size: 24px;\n  top: -6px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
