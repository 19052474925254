// src/CustomLoader.js
import React from "react";
import "./CustomLoader.css";

const CustomLoader = ({ text }) => {
  return (
    <div className="loader-wrapper">
      <div className="loader-container">
        <div className="loader-text">{text}</div>
        <div className="loader-dots">
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </div>
      </div>
    </div>
  );
};

export default CustomLoader;
